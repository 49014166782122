import React, { useEffect, useState, useContext } from "react";
import Text from "../../commons/Text";
import ShowAlert from "../../commons/ShowAlert";
import Challenge from "../../../api/Challenge";
import { AppContext } from "../../context/AppContext";
import { SolutionCardLoading } from "../SolutionCard";
import { useHistory } from "react-router-dom";
import { CommentOutlined } from "@material-ui/icons";

const copyReadmeTemplate = () => {
  Text.copyText("readme-file");
  ShowAlert.success("El contenido ha sido copiado");
};

const ChallengeSolutions = ({ currentChallenge, isVisible }) => {
  const [context, setContext] = useContext(AppContext);
  const history = useHistory();
  const [solutions, setSolutions] = useState([]);
  const [loading, setLoading] = useState(false);

  const goToSolution = (id) => {
    history.push(`/solutions/${id}`);
  };

  const getSolutions = () => {
    setLoading(true);
    Challenge.getSolutions(currentChallenge.id)
      .then((data) => {
        if (data.data) {
          setSolutions(data.data);
        }
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    if (isVisible) {
      getSolutions();
    }
  }, [isVisible]);
  return (
    <div className={!isVisible && "d-none"}>
      {loading && (
        <div class="row mt-4">
          <div class="col-md-4 mb-4">
            <SolutionCardLoading />
          </div>
          <div class="col-md-4 mb-4">
            <SolutionCardLoading />
          </div>
          <div class="col-md-4 mb-4">
            <SolutionCardLoading />
          </div>
        </div>
      )}
      {!loading && !solutions.length && (
        <div>Se el primero en enviar una solución :)</div>
      )}
      {!loading && solutions.length > 0 && (
        <div class="row mt-4 align-items-stretch">
          {solutions.map((solution) => {
            return (
              <div class="col-md-6 mb-4">
                <div
                  className="card cursor-pointer"
                  onClick={() => goToSolution(solution.id)}
                >
                  <div class="media card-body">
                    <img
                      src={solution?.user.avatar}
                      className="rounded-circle mr-3"
                      width="49px"
                    />
                    <div class="media-body">
                      <strong>{solution?.user.username}</strong>
                      <p>{solution?.description.slice(0, 80)}</p>

                      <p class="card-text text-truncate pb-0 small text-muted">
                        <CommentOutlined fontSize="small" />
                        <span className="align-middle ml-2">
                          {solution?.total_comments > 0
                            ? solution?.total_comments + " comentarios"
                            : "Comentar"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default ChallengeSolutions;
