import Config from "../Config";
import Auth from "./Auth";

const User = {
  profile,
  update,
  changePassword,
  searchPeople,
  featured,
  credits,
  createcontact,
  contacts,
};
export default User;

function profile(username) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(Config.API_URL + "/users/" + username, requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function searchPeople(searchFilters, nextPage = null) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  var url = nextPage ? nextPage : Config.API_URL + "/search/people";
  if (searchFilters && !nextPage) {
    const searchParams = new URLSearchParams(searchFilters).toString();
    url += searchParams && "?" + searchParams;
  }

  return fetch(url, requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function featured() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  var url = Config.API_URL + "/search/featuredpeople";

  return fetch(url, requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function update(user, username) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth.token(),
    },
    body: JSON.stringify(user),
  };

  return fetch(Config.API_URL + "/users/" + username, requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function changePassword(password_old, password, password_confirmation) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth.token(),
    },
    body: JSON.stringify({ password_old, password, password_confirmation }),
  };

  return fetch(Config.API_URL + "/users/change-password", requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function credits() {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth.token(),
    },
  };

  return fetch(Config.API_URL + "/users/credits", requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function contacts() {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth.token(),
    },
  };

  return fetch(Config.API_URL + "/users/contacts", requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function createcontact(contact_user_id) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth.token(),
    },
    body: JSON.stringify({ contact_user_id }),
  };

  return fetch(Config.API_URL + "/users/createcontact", requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}
