import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Logo from "../../media/images/holajuniors-logo.png";
import Auth from "../../api/Auth";

const AdminLayout = ({ children, ...rest }) => {
  return (
    <div class="container-fluid">
      <div class="row vh-100">
        <div class="col-sm-12 h-100 d-table">
          <div class="d-table-cell text-center align-middle">
            <img src={Logo} class="pulse-effect loading-app-logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
