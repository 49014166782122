import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton from "react-loading-skeleton";
import Text from "../commons/Text";
import { PlayArrow, Code, Reply, Delete } from "@material-ui/icons";
import ShowAlert from "../commons/ShowAlert";
import Challenge from "../../api/Challenge";
import { AppContext } from "../context/AppContext";

export const SolutionCardLoading = () => {
  return (
    <div class="card mb-3">
      <div class="card-body">
        <h5 class="card-title">
          <Skeleton />
        </h5>
        <p class="card-text">
          <Skeleton count={2} />
        </p>
      </div>
    </div>
  );
};
