import React, { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import Payment from "../../api/Payment";
import AccountPageTitle from "../user/AccountPageTitle";
import Moment from "react-moment";
import EmptyState from "../commons/EmptyState";
import CreditsPageTitle from "../credits/CreditsPageTitle";

const UserPayments = () => {
  const [paymentHistory, setPaymentHistory] = useState(null);
  const [loading, setLoading] = useState(true);

  const getPayments = async () => {
    setLoading(true);
    const payments = await Payment.userPayments();
    setPaymentHistory(payments.data);
    setLoading(false);
  };

  useEffect(() => {
    getPayments();
  }, []);

  return (
    <>
      <CreditsPageTitle title="Pagos" />
      {loading && "Buscando pagos"}
      {paymentHistory && paymentHistory.length > 0 && (
        <div class="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Pago</th>
                <th>Estado</th>
                <th>Procesador</th>
                <th>Fecha</th>
              </tr>
            </thead>
            <tbody>
              {paymentHistory.map((payment) => {
                return (
                  <tr>
                    <td>
                      {payment.currency} {payment.amount}
                    </td>
                    <td>{payment.status}</td>
                    <td>{payment.payment_processor}</td>
                    <td>
                      <Moment format="DD-MM-YYYY">{payment.created_at}</Moment>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <EmptyState
        title="No has realizado pagos"
        description="Cuando cargues créditos verás aquí el detalle de los pagos"
        icon="payments"
        actions={
          <Link to="/user/credits" className="btn btn-primary">
            Comprar créditos
          </Link>
        }
        show={!loading && !paymentHistory?.length}
      />
    </>
  );
};

export default UserPayments;
