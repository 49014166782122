import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { WorkOutline, Laptop, Public, Code } from "@material-ui/icons";
import ShowAlert from "../commons/ShowAlert";
import Config from "../../Config";
import Payment from "../../api/Payment";
import UserApi from "../../api/User";
import StripeBanner from "../payments/Stripe/StripeBanner";
import CreditsPageTitle from "../credits/CreditsPageTitle";

const UserCredits = () => {
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState(null);
  const [userCredits, setUserCredits] = useState(null);

  const checkout = async (planId) => {
    setLoading(true);
    const payment = await Payment.checkout(planId);
    if (payment.status == "ok") {
      window.location.href = payment.data;
    } else {
      ShowAlert.error("Ha ocurrido un error");
    }
    setLoading(false);
  };

  const pricingCardClass = [
    "card-pricing-one",
    "card-pricing-two",
    "card-pricing-three",
    "card-pricing-four",
  ];

  const getPlans = async () => {
    const plans = await Payment.plans();
    setPlans(plans.data);
  };
  const getCredits = async () => {
    const credits = await UserApi.credits();
    setUserCredits(credits.data);
  };
  useEffect(() => {
    getPlans();
    getCredits();
  }, []);

  return (
    <>
      <CreditsPageTitle title="Créditos" />
      {userCredits && (
        <h3 className="mb-5 p-y-2 alert alert-info bg-light border-0 d-inline-block">
          {userCredits.available_credits} créditos disponibles
        </h3>
      )}

      <h2 class="big-title">Cargar créditos</h2>
      <div>
        <strong>HolaMail</strong>: Se descontará 1 crédito por cada 5 emails que
        envíes.
        <br />
        <strong>HolaPersonal</strong>: Se descontará 1 crédito por cada perfil
        que adquieras directamente.
      </div>
      <br />
      {!loading && plans && (
        <div className="row">
          {plans.map((plan, index) => {
            return (
              <div className="col-md-4">
                <div
                  class={`card mb-3 cursor-pointer ${pricingCardClass[index]}`}
                  onClick={() => checkout(plan.id)}
                >
                  <div class="card-body">
                    <h5 class="card-title">
                      <div class="row">
                        <div className="col-6">
                          <strong>{plan.name}</strong>
                        </div>
                        <div className="col-6 text-right">
                          {plan.currency} {plan.price}
                        </div>
                      </div>
                    </h5>
                    <p class="card-text">
                      {plan.credits} créditos
                      <br />
                      Vigencia de uso: {plan.expiration_in_days} días
                    </p>
                    <p class="card-text">
                      <button
                        onClick={() => checkout(plan.id)}
                        className="btn btn-primary"
                      >
                        Comprar
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="w-100 text-center mb-5 pb-5">
        <StripeBanner />
      </div>
    </>
  );
};

export default UserCredits;
