import React, { useContext, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import ShowAlert from "../commons/ShowAlert";
import Challenge from "../../api/Challenge";

const SendSolution = ({ challenge, onSent }) => {
  const history = useHistory();
  const [context, setContext] = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const createSolution = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { challenge_id, repository_url, demo_url, description } = e.target;

    const message = await Challenge.create(
      challenge_id.value,
      repository_url.value,
      demo_url.value,
      description.value
    );
    if (message.status == "error") {
      ShowAlert.error(message.message.error);
      setLoading(false);
    } else {
      setShowModal(false);
      setLoading(false);
      onSent();
    }
  };

  const hideModal = () => {
    ShowAlert.confirm("¿Quieres salir del editor?").then((response) => {
      response.isConfirmed && setShowModal(false);
    });
  };

  return (
    <>
      <button
        className="btn btn-outline mb-5"
        onClick={() => {
          if (context.user) {
            setShowModal(!showModal);
          } else {
            history.push("/auth/login");
          }
        }}
        disabled={loading}
      >
        {loading ? "Enviando" : "Enviar mi solución"}
      </button>

      <Modal show={showModal} onHide={() => hideModal()} size="md">
        <form onSubmit={createSolution}>
          <input type="hidden" name="challenge_id" value={challenge.id} />
          <Modal.Body>
            <h2 className="big-title-2 mb-3">Enviar mi solución</h2>

            <div class="form-group">
              <label>URL del repositorio público en GitHub</label>
              <input type="text" name="repository_url" class="form-control" />
            </div>

            <div class="form-group">
              <label>URL demo (opcional)</label>
              <input type="text" name="demo_url" class="form-control" />
            </div>
            <p>
              <small class="form-text text-muted">
                Ejemplo: Si participas en un desafío frontend, tal vez quieras
                compartir el resultado en funcionamiento.
              </small>
            </p>

            <div class="form-group">
              <label>
                Cuenta lo que has hecho o pide feedback de la comunidad
              </label>
              <textarea
                name="description"
                class="form-control"
                rows="9"
              ></textarea>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button type="button" onClick={hideModal} class="btn btn-outline">
              Cancelar
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? "Enviando" : "Publicar"}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
export default SendSolution;
