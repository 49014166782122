import Config from "../Config";
import Auth from "./Auth";

const Challenge = {
  search,
  getBySlug,
  getSolutions,
  getSolutionById,
  create,
  deleteSolution,
  deleteComment,
  createComment,
  getSolutionByUserId,
};
export default Challenge;

function search() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(Config.API_URL + "/challenges", requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function getBySlug(slug) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(Config.API_URL + "/challenges/" + slug, requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function getSolutions(id = null) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth.token(),
    },
  };

  let url = Config.API_URL + "/challenges_solutions";
  if (id) {
    url += "?challenge_id=" + id;
  }

  return fetch(url, requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function create(challenge_id, repository_url, demo_url, description) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth.token(),
    },
    body: JSON.stringify({
      challenge_id,
      repository_url,
      demo_url,
      description,
    }),
  };

  return fetch(Config.API_URL + "/challenges_solutions", requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function deleteSolution(solution_id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth.token(),
    },
  };

  return fetch(
    Config.API_URL + "/challenges_solutions/" + solution_id,
    requestOptions
  )
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function createComment(challenge_solution_id, comment, comment_parent_id) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth.token(),
    },
    body: JSON.stringify({
      challenge_solution_id,
      comment,
      comment_parent_id,
    }),
  };

  return fetch(Config.API_URL + "/challenges_comments", requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function deleteComment(comment_id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth.token(),
    },
  };

  return fetch(
    Config.API_URL + "/challenges_comments/" + comment_id,
    requestOptions
  )
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function getSolutionById(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth.token(),
    },
  };

  return fetch(Config.API_URL + "/challenges_solutions/" + id, requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function getSolutionByUserId(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth.token(),
    },
  };

  return fetch(
    Config.API_URL + "/challenges_solutions?user_id=" + id,
    requestOptions
  )
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}
