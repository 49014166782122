import React, { Component } from "react";
import render from "react-dom";
import "./css/Bootstrap.css";
import "./css/App.css";
import AppRoute from "./routes/AppRoute";
import { AppProvider } from "./components/context/AppContext";

class App extends React.Component {
  render() {
    return (
      <AppProvider>
        <AppRoute />
      </AppProvider>
    );
  }
}
export default App;
