import React, { useState, useEffect } from "react";
import User from "../../api/User";
import FreelanceCard, { FreelanceCardLoading } from "../user/FreelanceCard";

const UsersRanking = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userSelected, setUserSelected] = useState({});

  const handleClose = () => {
    setShowModal(false);
    setUserSelected({});
  };
  const handleShow = (user) => {
    setShowModal(true);
    setUserSelected(user);
  };

  const doSearchPeople = () => {
    setLoading(true);
    User.featured().then((users) => {
      if (users.status === "ok" && users?.data) {
        setUsers(users.data);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    doSearchPeople();
  }, []);

  return (
    <>
      <div class="row big-intro">
        <div class="col-sm-12 col-md-8 offset-md-2 text-center">
          <h1 className="big-title mb-4">Ranking de usuarios</h1>
        </div>
        {!loading && (
          <div className="col-12 text-center my-3 py-2">
            {/* {totalResults && (
              <SendProposalModal
                searchFilters={searchFilters}
                totalResults={totalResults}
              />
            )} */}
          </div>
        )}
      </div>

      {loading && (
        <div class="row">
          <div class="col-md-3 fade-in">
            <FreelanceCardLoading />
          </div>
          <div class="col-md-3 fade-in">
            <FreelanceCardLoading />
          </div>
        </div>
      )}

      <div class="row">
        <div class="col-12">
          {users && (
            <div>
              <div class="row">
                {users.map((user) => {
                  return (
                    <div class="col-md-3 fade-in" key={user.id}>
                      <FreelanceCard user={user} handleShow={handleShow} />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UsersRanking;
