import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Jobs from "../../api/Jobs";
import ShowAlert from "../commons/ShowAlert";
import ContentPageTitle from "./ContentPageTitle";
import Content from "../../api/Content";

const ErrorFeedback = ({ feedback, show }) => {
  if (show) {
    return <div className="invalid-feedback d-block">{feedback}</div>;
  } else {
    return "";
  }
};

const LinkPreview = ({ title, image }) => {
  if (!title && !image) {
    return null; // Si no hay datos, no se muestra nada
  }

  return (
    <div className="link-preview mt-3">
      {image && (
        <img src={image} alt="Vista previa" className="img-fluid mb-2" />
      )}
      {title && <h5>{title}</h5>}
    </div>
  );
};

const CreateContent = () => {
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formCategories, setFormCategories] = useState([]);
  const [formSkills, setFormSkills] = useState([]);
  const [activeTab, setActiveTab] = useState("post"); // 'post' o 'link'

  const [content, setContent] = useState({
    title: "",
    description: "",
    category_id: null,
    skills_ids: "",
    url: "",
    image: null,
  });

  useEffect(() => {
    Jobs.getMetadata().then((response) => {
      let skills = response.data.skills.map((skill) => {
        skill.value = skill.id;
        skill.label = skill.name;
        return skill;
      });
      let categories = response.data.categories.map((category) => {
        category.value = category.id;
        category.label = category.name;
        return category;
      });

      setFormSkills(skills);
      setFormCategories(categories);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors({});

    const formData = new FormData();

    formData.append("title", content.title);
    formData.append("description", content.description);
    formData.append("category_id", content.category_id);
    formData.append("skills_ids", content.skills_ids);
    formData.append("type", activeTab);

    if (activeTab === "post" && content.image) {
      formData.append("image", content.image); // Solo el archivo
    }

    if (activeTab === "link") {
      formData.append("url", content.url);
    }

    Content.create(formData)
      .then((data) => {
        if (data.status === "error") {
          setErrors(data.message.error);
          ShowAlert.error("Hemos encontrado errores", "error");
        } else {
          ShowAlert.success("Contenido publicado con éxito", "success").then(
            () => history.push("/contents/index")
          );
        }
      })
      .finally(() => setLoading(false));
  };

  const updateInput = (e) => {
    setContent({ ...content, [e.target.name]: e.target.value });
  };

  const validateUrl = (str) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(str);
  };

  const updateUrl = (e) => {
    if (validateUrl(e.target.value)) {
      Content.urlPreview(e.target.value).then((previewData) => {
        setContent({
          ...content,
          title: previewData?.data?.title,
          image: previewData?.data?.image,
        });
      });
    }
  };

  const updateReactSelect = (name, selected) => {
    const allSelectedOptions = Array.isArray(selected)
      ? selected.map((currentValue) => currentValue.value).join(", ")
      : selected.value;
    setContent({ ...content, [name]: allSelectedOptions });
  };

  const toggleTab = (tab) => {
    setActiveTab(tab);
    setContent({ ...content, imagePreview: null, image: null });
  };

  return (
    <>
      <ContentPageTitle title="Crear Post" />

      <div className="row pt-3 pb-2 mb-3 update-profile">
        <div className="col-md-6 offset-md-3">
          <div className="mb-3">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === "post" ? "active" : ""}`}
                  onClick={() => toggleTab("post")}
                >
                  Crear Post
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === "link" ? "active" : ""}`}
                  onClick={() => toggleTab("link")}
                >
                  Compartir Link
                </button>
              </li>
            </ul>
          </div>

          <form
            onSubmit={handleSubmit}
            method="POST"
            encType="multipart/form-data"
          >
            {activeTab === "post" && (
              <>
                <div className="form-group">
                  <label>Título *</label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    value={content.title}
                    onChange={updateInput}
                    disabled={loading}
                  />
                  <ErrorFeedback feedback={errors.title} show={errors.title} />
                </div>

                <div className="form-group">
                  <input
                    type="file"
                    id="imageUpload"
                    className="d-none"
                    name="image"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          setContent({
                            ...content,
                            image: file,
                            imagePreview: e.target.result,
                          });
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                    disabled={loading}
                  />

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      document.getElementById("imageUpload").click()
                    }
                  >
                    Seleccionar Imagen
                  </button>

                  {content?.imagePreview && (
                    <div className="image-preview mt-3">
                      <img
                        src={content.imagePreview}
                        alt="Vista previa"
                        className="img-fluid rounded"
                        style={{ maxHeight: "200px" }}
                      />
                    </div>
                  )}
                  <ErrorFeedback feedback={errors.image} show={errors.image} />

                  <small id="passwordHelpBlock" class="form-text text-muted">
                    Recomendado 800x600px
                  </small>
                </div>
              </>
            )}

            {activeTab === "link" && (
              <div className="form-group">
                <label>URL *</label>
                <input
                  type="text"
                  className="form-control"
                  name="url"
                  value={content.url}
                  onChange={updateInput}
                  onBlur={updateUrl}
                  disabled={loading}
                />
                <ErrorFeedback feedback={errors.url} show={errors.url} />

                <LinkPreview title={content.title} image={content.image} />
              </div>
            )}

            <div className="form-group">
              <label>Descripción</label>
              <textarea
                className="form-control"
                name="description"
                rows="6"
                value={content.description}
                onChange={updateInput}
                disabled={loading}
              />
              <ErrorFeedback
                feedback={errors.description}
                show={errors.description}
              />
            </div>

            <div className="form-group">
              <label>Categoría *</label>
              <Select
                options={formCategories}
                className="react-select"
                classNamePrefix="react-select"
                onChange={(selected) =>
                  setContent({ ...content, category_id: selected.value })
                }
                isDisabled={loading}
              />
              <ErrorFeedback
                feedback={errors.category_id}
                show={errors.category_id}
              />
            </div>

            <div className="form-group">
              <label>Habilidades y tecnologías</label>
              <Select
                options={formSkills}
                isMulti
                className="react-select"
                classNamePrefix="react-select"
                onChange={(selected) =>
                  updateReactSelect("skills_ids", selected)
                }
                isDisabled={loading}
              />
              <ErrorFeedback
                feedback={errors.skills_ids}
                show={errors.skills_ids}
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
            >
              {loading ? "Guardando..." : "Publicar contenido"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateContent;
