import React from "react";
import Payments from "../../media/images/empty-states/empty-payments.png";
import Inbox from "../../media/images/empty-states/EmptyInbox.png";

const EmptyState = (props) => {
  return (
    <div class={`row text-center ${!props.show && "d-none"}`}>
      <div class="col-12">
        {props.icon && (
          <div class="mb-5 mt-3">
            <img src={icon(props.icon)} />
          </div>
        )}

        <h4>{props.title}</h4>
        <p>{props.description}</p>

        {props.actions && <p>{props.actions}</p>}
      </div>
    </div>
  );
};
export default EmptyState;

const icon = (state) => {
  switch (state) {
    case "products":
      return "//devolvería el ícono";
      break;
    case "payments":
      return Payments;
      break;
    case "inbox":
      return Inbox;
      break;
  }
};
