import React, { useContext, useState, useEffect } from "react";
import Challenge from "../../api/Challenge";
import ChallengeCard, { ChallengeCardLoading } from "./ChallengeCard";
import WebDevelopmentImg from "../../media/images/web/business/web-development-bw.png";

const SearchChallenges = () => {
  const [loading, setLoading] = useState(false);
  const [challenges, setChallenges] = useState([]);

  useEffect(() => {
    setLoading(true);
    Challenge.search()
      .then((data) => {
        setChallenges(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="row big-intro mt-5 mb-3">
        <div className="col-md-7">
          <h4 className="pre-big-title">Nuevo!</h4>
          <h1 className="big-title mb-5">
            Aprende programando proyectos reales
          </h1>
          <p className="lead">
            Estos desafíos han sido creado especialmente para mejorar tus
            habilidades y prepararte para el mundo real.
            <br />
            <strong className="lead mb-4 font-weight-bold">
              Nuevos desafíos cada semana!
            </strong>
          </p>
        </div>
        <div className="col-md-5 order-1 order-md-2 mb-2">
          <img src={WebDevelopmentImg} className="img-fluid" />
        </div>
      </div>

      {loading && (
        <div class="row">
          <div class="col-md-4 fade-in">
            <ChallengeCardLoading />
          </div>
          <div class="col-md-4 fade-in">
            <ChallengeCardLoading />
          </div>
          <div class="col-md-4 fade-in">
            <ChallengeCardLoading />
          </div>
        </div>
      )}

      {challenges && (
        <div class="row align-items-stretch">
          {challenges.map((currentChallenge) => {
            return (
              <div class="col-md-4 mb-4">
                <ChallengeCard challenge={currentChallenge} />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default SearchChallenges;
