import React, { useContext, useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { AppContext } from '../context/AppContext'
import Auth from '../../api/Auth'

const Logout = () => {

  const [context, setContext] = useContext(AppContext);
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    Auth.logout().then(isOk => {
        setRedirect(true)
        setContext({})
    })
  },[])

  return (
      redirect ? (
        <Redirect to="/" />
      )
      :
      <div class="row vh-100">
        <div class="col-sm-12 h-100 d-table">
          <div class="d-table-cell text-center align-middle">
            Cerrando sesión...
          </div>
        </div>
      </div>
  )
}

export default Logout
