import React from "react";
import { Link } from "react-router-dom";
import {
  LinkedIn,
  Instagram,
  Telegram,
  Twitter,
  GitHub,
} from "@material-ui/icons";
import Logo from "../../media/images/holajuniors-logo.png";

const Footer = (props) => {
  return (
    <div class="row py-5 footer">
      <div class="col-md-8">
        <ul class="nav">
          <li>
            <a
              href="https://www.linkedin.com/company/holajuniors"
              target="_blank"
              class="nav-link"
            >
              Ayuda
            </a>
          </li>
          <li class="nav-item">
            <Link to="/website/terms" class="nav-link">
              Términos
            </Link>
          </li>
          <li class="nav-item">
            <Link to="/website/privacy" class="nav-link">
              Privacidad
            </Link>
          </li>
        </ul>
      </div>
      <div class="col-md-4">
        <ul class="nav float-md-right">
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://www.linkedin.com/company/holajuniors/"
              target="_blank"
            >
              <LinkedIn />
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://www.instagram.com/holajuniors/"
              target="_blank"
            >
              <Instagram />
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://t.me/holajuniors" target="_blank">
              <Telegram />
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://twitter.com/holajuniors"
              target="_blank"
            >
              <Twitter />
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://github.com/holajuniors"
              target="_blank"
            >
              <GitHub />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
