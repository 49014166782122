import React from "react";
import Text from "../../commons/Text";

const ChallengeInstructions = ({ currentChallenge, isVisible }) => {
  return (
    <div className={!isVisible && "d-none"}>
      <p>
        <h2 className="big-title-2">Instrucciones</h2>
      </p>
      <p>{Text.nl2br(currentChallenge.description)}</p>

      <p class="card-text text-truncate skill-tags">
        {currentChallenge.skills.length > 0 &&
          currentChallenge.skills.map((skill) => {
            return Text.textToBadge(skill.name);
          })}
      </p>

      <p>
        {currentChallenge.image && (
          <a href={currentChallenge.image} target="_blank">
            <img src={currentChallenge.image} className="img-fluid" />
          </a>
        )}
      </p>
    </div>
  );
};
export default ChallengeInstructions;
