import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Logo from "../../media/images/holajuniors-logo.png";
import {
  Home,
  Face,
  Search,
  Stars,
  PermContactCalendar,
  Code,
  Work,
} from "@material-ui/icons";

const Sidebar = (props) => {
  const [context, setContext] = useContext(AppContext);
  const user = context.user;

  return (
    <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block sidebar collapse">
      <div class="sidebar-sticky pt-3">
        <div class="row mt-3">
          <div class="col-sm-12 logotype-container">
            <Link to="/user/dashboard">
              <img src={Logo} />
            </Link>
          </div>
        </div>

        <ul class="nav flex-column mt-5">
          <li class="nav-item mb-1">
            <Link to="/user/dashboard" className="nav-link">
              <Home />
              <span>Inicio</span>
            </Link>
          </li>
          <li class="nav-item mb-1">
            <Link to="/jobs/index" className="nav-link">
              <Work />
              <span>Mis empleos</span>
            </Link>
          </li>
          <li class="nav-item mb-1">
            <Link to="/challenges/search" className="nav-link">
              <Code />
              <span>Desafíos</span>
            </Link>
          </li>
          <li class="nav-item mb-1">
            <Link to="/search/people" className="nav-link">
              <Search />
              <span>Buscar talento</span>
            </Link>
          </li>
          {user.available_credits > 0 && (
            <>
              <li class="nav-item mb-1">
                <Link to="/user/credits" className="nav-link">
                  <Stars />
                  <span>Créditos</span>
                </Link>
              </li>
              <li class="nav-item mb-1">
                <Link to="/user/contacts" className="nav-link">
                  <PermContactCalendar />
                  <span>Contactos</span>
                </Link>
              </li>
            </>
          )}
          <li class="nav-item mb-1">
            <Link to="/user/account" className="nav-link">
              <Face />
              <span>Mi cuenta</span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
