import React from "react";

const TabsMenu = ({ selectedTab, onClick }) => {
  return (
    <div className="mb-4">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            class={`nav-link cursor-pointer ${
              selectedTab === "instructions" && "active"
            }`}
            onClick={() => onClick("instructions")}
          >
            Desafío
          </a>
        </li>
        <li class="nav-item">
          <a
            class={`nav-link cursor-pointer ${
              selectedTab === "solutions" && "active"
            }`}
            onClick={() => onClick("solutions")}
          >
            Soluciones
          </a>
        </li>
        <li class="nav-item">
          <a
            class={`nav-link cursor-pointer ${
              selectedTab === "help" && "active"
            }`}
            onClick={() => onClick("help")}
          >
            Ayuda
          </a>
        </li>
      </ul>
    </div>
  );
};
export default TabsMenu;
