import React, { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import EmailMessages from "../../api/EmailMessages";
import AccountPageTitle from "../user/AccountPageTitle";
import Moment from "react-moment";
import EmptyState from "../commons/EmptyState";
import CreditsPageTitle from "../credits/CreditsPageTitle";

const UserMessages = () => {
  const [loading, setLoading] = useState(true);
  const [userMessages, setUserMessages] = useState(null);

  const getMessages = async () => {
    setLoading(true);
    const messages = await EmailMessages.list();
    setUserMessages(messages);
    setLoading(false);
  };

  useEffect(() => {
    getMessages();
  }, []);

  return (
    <>
      <div class="row page-title">
        <div class="col-12">
          <h1 class="big-title-2 mb-5">Mensajes</h1>

          {!loading && userMessages?.length > 0 && (
            <div class="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Mensaje</th>
                    <th>Destinatarios</th>
                    <th>Enviados</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {userMessages.map((message) => {
                    return (
                      <tr>
                        <td>{message.subject}</td>
                        <td>{message.recipients.length}</td>
                        <td>
                          {message.recipients.filter((r) => r.sent).length}
                        </td>
                        <td>
                          {message.approved
                            ? message.completed
                              ? "Completado"
                              : "En curso"
                            : "Pendiente"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}

          {!loading && userMessages?.length == 0 && (
            <div>No tienes mensajes</div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserMessages;
