import React, { useContext, useState, useEffect } from "react";
import Jobs from "../../api/Jobs";
import JobCard, { ChallengeCardLoading } from "../jobs/JobCard";
import WebDevelopmentImg from "../../media/images/web/business/web-development-bw.png";
import Select from "react-select";
import { Link, useLocation } from "react-router-dom";

const SearchJobs = () => {
  const [loading, setLoading] = useState(false);
  const [jobAds, setJobAds] = useState([]);

  const [searchFilters, setSearchFilters] = useState({});
  const [formCountries, setFormCountries] = useState([]);
  const [formSkills, setFormSkills] = useState([]);
  const [formTypeOfJob, setFormTypeOfJob] = useState([]);
  const [formSeniorities, setFormSeniorities] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [totalResults, setTotalResults] = useState(0);

  const updateReactSelect = (name, selected) => {
    setShowFilters(false);
    const allSelectedOptions = selected
      ? Array.isArray(selected)
        ? selected.map((currentValue) => currentValue.id).join(", ")
        : selected.id
      : null;

    const theFilters = { ...searchFilters, [name]: allSelectedOptions };
    setSearchFilters(theFilters);
    doSearchJobs(theFilters);
  };

  const doSearchJobs = (params = null, loadMore = false, page = false) => {
    setLoading(true);
    Jobs.search(params, page)
      .then((response) => {
        setLoading(false);

        if (response.status == "ok" && response.data.total) {
          if (loadMore) {
            setJobAds([...jobAds, ...response.data.data]);
          } else {
            setJobAds(response.data.data);
          }
          setNextPage(response.data?.next_page_url || null);
          setTotalResults(response.data?.total);
        } else {
          setJobAds([]);
          setNextPage(null);
          setTotalResults(0);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    doSearchJobs();

    Jobs.getMetadata().then((response) => {
      let skills = response.data.skills.map((skill) => {
        skill.value = skill.id;
        skill.label = skill.name;
        return skill;
      });
      let typesofjob = response.data.typesofjob
        .map((job) => {
          job.value = job.id;
          job.label = job.name;
          return job;
        })
        .filter((job) => job.id != 4);
      let seniorities = response.data.seniorities.map((seniority) => {
        seniority.value = seniority.id;
        seniority.label = seniority.name;
        return seniority;
      });
      let countries = response.data.countries.map((country) => {
        country.value = country.id;
        country.label = country.name;
        return country;
      });

      setFormSkills(skills);
      setFormTypeOfJob(typesofjob);
      setFormSeniorities(seniorities);
      setFormCountries(countries);
    });
  }, []);

  return (
    <>
      <div className="row big-intro my-5">
        <div className="col-sm-12 col-md-8 offset-md-2 text-center">
          <h1 className="big-title mb-4">Hola, Junior 👋</h1>
          <p className="lead mb-4">
            <h1 className="lead">
              Empleos para Programadores Junior compartidos por la comunidad
            </h1>
          </p>

          <p>
            <Link to="/jobs/create" className="btn btn-large btn-primary">
              Publicar un empleo
            </Link>
          </p>
        </div>
      </div>

      <div class="row mb-3 d-block d-md-none">
        <div class="col-md-3">
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="btn btn-block btn-secondary"
          >
            {showFilters ? "Ocultar filtros" : "Refinar búsqueda"}
          </button>
        </div>
      </div>

      <div className={`row d-none d-md-block ${showFilters && "d-block"}`}>
        <div className="col-12 col-lg-10 offset-lg-1">
          <div class="row mb-3 mt-4">
            <div class="col-md-3">
              <div class="form-group">
                {formSkills.length > 0 && (
                  <Select
                    options={formSkills}
                    isClearable
                    className="react-select react-select-light"
                    classNamePrefix="react-select"
                    name="filter_skill"
                    placeholder="Habilidades"
                    onChange={(value) =>
                      updateReactSelect("filter_skill", value)
                    }
                  />
                )}
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                {formSkills.length > 0 && (
                  <Select
                    isClearable
                    options={formSeniorities}
                    className="react-select react-select-light"
                    classNamePrefix="react-select"
                    name="filter_seniority"
                    placeholder="Experiencia"
                    onChange={(value) =>
                      updateReactSelect("filter_seniority", value)
                    }
                  />
                )}
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                {formSkills.length > 0 && (
                  <Select
                    isClearable
                    options={formTypeOfJob}
                    className="react-select react-select-light"
                    classNamePrefix="react-select"
                    name="filter_type_of_job"
                    placeholder="Modalidad"
                    onChange={(value) =>
                      updateReactSelect("filter_type_of_job", value)
                    }
                  />
                )}
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                {formCountries.length > 0 && (
                  <Select
                    isClearable
                    options={formCountries}
                    className="react-select react-select-light"
                    classNamePrefix="react-select"
                    name="filter_country"
                    placeholder="País"
                    onChange={(value) =>
                      updateReactSelect("filter_country", value)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1 fade-in">
            <ChallengeCardLoading />
          </div>
        </div>
      )}

      {jobAds && jobAds.length > 0 && (
        <div class="row align-items-stretch">
          {jobAds.map((currentJob) => {
            return (
              <div class="col-12 col-lg-10 offset-lg-1">
                <JobCard job={currentJob} />
              </div>
            );
          })}
        </div>
      )}

      <div class="row my-3">
        <div className="col-12 text-center">
          {nextPage && (
            <button
              onClick={() => doSearchJobs(searchFilters, true, nextPage)}
              disabled={loading}
              className="btn btn-secondary"
            >
              {loading ? "Buscando" : "Ver más"}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchJobs;
