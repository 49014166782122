import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Challenge from "../../api/Challenge";
import TabsMenu from "./tabs/TabsMenu";
import ChallengeInstructions from "./tabs/ChallengeInstructions";
import ChallengeHelp from "./tabs/ChallengeHelp";
import ChallengeSolutions from "./tabs/ChallengeSolutions";
import SendSolution from "./SendSolution";

const ShowChallenge = ({ challenge }) => {
  const [loading, setLoading] = useState();
  const [currentChallenge, setCurrentChallenge] = useState();
  const { slug } = useParams();
  const [selectedTab, setSelectedTab] = useState("instructions");

  useEffect(() => {
    setLoading(true);
    Challenge.getBySlug(slug)
      .then((data) => {
        if (data.data) {
          setCurrentChallenge(data.data);
        } else {
          window.location.href = "/";
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="row mb-3">
      {!loading && currentChallenge && (
        <div className="col-sm-12 col-md-10 offset-md-1 mb-5">
          <strong className="badge badge-success">
            {currentChallenge.difficulty}
          </strong>

          <h1 className="big-title mb-3">{currentChallenge.title}</h1>

          <SendSolution
            challenge={currentChallenge}
            onSent={() => {
              setSelectedTab("");
              setSelectedTab("solutions");
            }}
          />

          <TabsMenu
            selectedTab={selectedTab}
            onClick={(tab) => setSelectedTab(tab)}
          />

          <ChallengeInstructions
            currentChallenge={currentChallenge}
            isVisible={selectedTab === "instructions"}
          />
          <ChallengeHelp
            currentChallenge={currentChallenge}
            isVisible={selectedTab === "help"}
          />
          <ChallengeSolutions
            currentChallenge={currentChallenge}
            isVisible={selectedTab === "solutions"}
          />
        </div>
      )}
    </div>
  );
};
export default ShowChallenge;
