import React, { useContext, useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Auth from "../../api/Auth";
import User from "../../api/User";
import Config from "../../Config";
import Loading from "../commons/Loading";
import Text from "../commons/Text";
import Challenge from "../../api/Challenge";

import {
  WorkOutline,
  Laptop,
  Public,
  Code,
  LinkedIn,
  GitHub,
  CheckCircle,
} from "@material-ui/icons";
import ShowAlert from "../commons/ShowAlert";

const Profile = (props) => {
  const { username } = useParams();
  const history = useHistory();

  const [context, setContext] = useContext(AppContext);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [solutions, setSolutions] = useState([]);
  const contactInfo = "En breve podrás contactar a otros usuarios";

  const getSolutions = (user_id) => {
    Challenge.getSolutionByUserId(user_id).then((data) => {
      if (data.data) {
        setSolutions(data.data);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    User.profile(username).then((data) => {
      if (data.status == "error") {
        history.push("/");
      } else {
        if (data.data) {
          setUser(data.data);
          getSolutions(data.data.id);
          document.title = "@" + data.data.username;
        } else {
          history.push("/");
        }
      }
      setLoading(false);
    });
  }, [context.user]);

  useEffect(() => {
    document.body.style.backgroundColor = "#fbfbfb";

    return () => (document.body.style.backgroundColor = "#ffffff");
  }, []);

  return (
    <>
      <div className="row my-4 ">
        <div className="col-md-8 offset-md-2 mb-3">
          <div className="row mb-5 align-items-center text-center text-md-left">
            <div className="col-6 col-md-3 offset-3 offset-md-0 mb-4">
              {user.avatar && (
                <img
                  src={user.avatar}
                  className="avatar rounded-circle w-100"
                />
              )}
            </div>

            <div className="col-12 col-md-9">
              <h1 className="big-title-profile">{user.profile_title}</h1>
              <p>
                {user.available_to_work && (
                  <div className="text-success mb-3">
                    <CheckCircle fontSize="small" /> Disponible para trabajar
                  </div>
                )}

                <ul class="nav justify-content-center justify-content-md-start">
                  {user?.website_url && (
                    <li class="nav-item">
                      <a
                        href={user.website_url}
                        className="mr-3 text-secondary"
                        target="_blank"
                      >
                        <Public />
                      </a>
                    </li>
                  )}

                  {user?.linkedin_url && (
                    <li class="nav-item">
                      <a
                        href={user.linkedin_url}
                        className="mr-3 text-secondary"
                        target="_blank"
                      >
                        <LinkedIn />
                      </a>
                    </li>
                  )}

                  {user?.github_url && (
                    <li class="nav-item">
                      <a
                        href={user.github_url}
                        className="mr-3 text-secondary"
                        target="_blank"
                      >
                        <GitHub />
                      </a>
                    </li>
                  )}
                </ul>
              </p>
            </div>
          </div>

          <hr />

          <div className="row mt-5 mb-5">
            <div className="col-12 col-md-7">
              <h1 className="big-title-2 mb-4">Acerca de</h1>
              <p>{user.bio && Text.nl2br(user.bio)}</p>
            </div>
            <div className="col-12 col-md-5">
              <ul className="public-profile-info mb-4">
                {user?.seniority && (
                  <li>
                    <WorkOutline /> <span>{user.seniority.name}</span>
                  </li>
                )}
                {user?.type_of_job && (
                  <li>
                    <Laptop /> <span>{user.type_of_job.name}</span>
                  </li>
                )}
                {user?.country && (
                  <li>
                    <Public />
                    <span>{user.country.name}</span>
                  </li>
                )}
              </ul>
              <div className="skill-tags mb-4">
                {user.user_skills &&
                  user.user_skills.map((skill) => {
                    return Text.textToBadge(skill.name);
                  })}
              </div>
            </div>
          </div>

          {!loading && solutions.length > 0 && (
            <h1 className="big-title-2 mb-4">Desafíos</h1>
          )}

          {!loading && solutions.length > 0 && (
            <div class="row mt-4 align-items-stretch">
              {solutions.map((solution) => {
                return (
                  <div class="col-12 mb-4">
                    <div className="card">
                      <div class="media card-body">
                        <img
                          src={solution?.user.avatar}
                          className="rounded-circle mr-3"
                          width="29px"
                        />
                        <div class="media-body">
                          <strong>{solution?.user.username}</strong>
                          <p>{solution?.description.slice(0, 80)}</p>
                          <p>
                            <a
                              href={`/solutions/${solution.id}`}
                              className="text-primary"
                            >
                              Ver solución
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Profile;
