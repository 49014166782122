import React from "react";

const AvatarList = ({ users }) => {
  return (
    <ul className="avatar-list">
      {users.map((user, index) => (
        <li>
          <img src={user.avatar} alt={user.name} className="avatar" />
        </li>
      ))}
    </ul>
  );
};
export default AvatarList;
