import Config from '../Config'
const Auth = {
  login,
  logout,
  register,
  recovery,
  reset,
  me,
  token
}
export default Auth

function login(email, password) {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({email, password})
  }

  return fetch(Config.API_URL + '/auth/login', requestOptions)
    .then(function(response){
      return response.json()
    })
    .then(function(json){
      setSessionCookies(json)
      return json
    })
}

function register(name, username, email, password, password_confirmation) {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({name, username, email, password, password_confirmation})
  }

  return fetch(Config.API_URL + '/auth/register', requestOptions)
    .then(function(response){
      return response.json()
    })
    .then(function(json){
      setSessionCookies(json)
      return json
    })
}

function recovery(email) {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({email})
  }

  return fetch(Config.API_URL + '/auth/recovery', requestOptions)
    .then(function(response){
      return response.json()
    })
    .then(function(json){
      return json
    })
}

function reset(password, password_confirmation, email, token) {

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({password, password_confirmation, email, token})
  }

  return fetch(Config.API_URL + '/auth/reset', requestOptions)
    .then(function(response){
      return response.json()
    })
    .then(function(json){
      return json
    })
}

function logout() {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + Auth.token(),
    },
  }

  return fetch(Config.API_URL + '/auth/logout', requestOptions)
    .then(function(response){
      return response.json()
    })
    .then(function(json){
      removeSessionCookies()
      return json
    })
}

function me(email, password) {

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + Auth.token(),
    }
  }

  return fetch(Config.API_URL + '/auth/me', requestOptions)
    .then(function(response){
      return response.json()
    })
    .then(function(json){
      if ( json.status == 'ok' ) {
        return json.data
      } else {
        return {}
      }
    })
}

function token() {
  return localStorage['token']
}

function setSessionCookies(json) {
  if ( json.token ) {
      localStorage['user'] = JSON.stringify(json.user)
      localStorage['token'] = json.token
      localStorage['expires_in'] = json.expires_in
  }
}

function removeSessionCookies(json) {
  localStorage.removeItem('user')
  localStorage.removeItem('token')
  localStorage.removeItem('expires_in')
}
