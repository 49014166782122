import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../components/context/AppContext";
import User from "../api/User";
import ProgrammingImg from "../media/images/web/business/programming.png";
import TeachingImg from "../media/images/web/business/teaching.png";
import WorkingImg from "../media/images/web/business/laptop-at-home.png";
import AvatarList from "./user/AvatarList";

const Freelancers = () => {
  const [context, setContext] = useContext(AppContext);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    User.featured().then((users) => {
      if (users.status === "ok" && users?.data) {
        setUsers(users.data);
      }
    });
  }, []);

  return (
    <>
      <div className="row big-intro my-5">
        <div className="col-sm-12 col-md-8 offset-md-2 text-center">
          <h1 className="big-title mb-4">
            Mejora tu código,
            <br /> haz contactos
          </h1>
          <p className="lead mb-4">
            Crea tu perfil gratis, participa en desafíos y amplía tu red.
            <br />
            Ya somos <span className="font-weight-bold">más de 2500</span> devs!
          </p>

          <p>
            {context?.user ? (
              <Link to="/challenges/search" className="btn btn-action">
                Ver desafíos
              </Link>
            ) : (
              <Link to="/auth/register" className="btn btn-action">
                Unirme ahora
              </Link>
            )}
          </p>
        </div>

        <div className="col-12 text-center mt-3">
          <Link to="/search/people">
            {users.length > 0 && (
              <AvatarList
                users={users
                  .slice(0, 5)
                  .filter(
                    (user) => user.avatar.split("/").at(-1) !== "default.png"
                  )}
              />
            )}
          </Link>
        </div>
      </div>

      <div className="row d-flex flex-row justify-content-center align-items-center my-5 py-5">
        <div className="col-md-6 order-2 order-md-1">
          <h2 className="big-title mb-3">Proyectos reales</h2>
          <p className="lead mb-4">
            Aprende creando desafíos propuestos por programadores experimentados
            y aprende resolviendo{" "}
            <strong className="font-weight-bold">
              problemas del mundo real
            </strong>
            .
          </p>
          <p>
            <Link to="/challenges/search" className="btn btn-primary btn-lg">
              Ver los desafíos
            </Link>
          </p>
        </div>
        <div className="col-md-5 order-1 order-md-2 mb-4">
          <img
            src={ProgrammingImg}
            className="img-fluid"
            alt="Working"
            width="100%"
            height="auto"
          />
        </div>
      </div>

      <div className="row d-flex flex-row justify-content-center align-items-center my-5 py-5">
        <div className="col-md-6 order-md-2">
          <h2 className="big-title mb-3">Comunidad</h2>
          <p className="lead mb-4">
            Comparte tu código con la comunidad para pedir ayuda o ideas que
            mejoren tus habilidades.
          </p>
          <p>
            <Link to="/auth/register" className="btn btn-outline btn-lg">
              Quiero ser parte
            </Link>
          </p>
        </div>
        <div className="col-md-5 order-md-1 mb-2">
          <img
            src={TeachingImg}
            className="img-fluid flip-horizontal"
            alt="Working"
            width="100%"
            height="auto"
          />
        </div>
      </div>

      <div className="row d-flex flex-row justify-content-center align-items-center my-5 py-5">
        <div className="col-md-6 order-2 order-md-1">
          <h2 className="big-title mb-3">Oportunidades laborales</h2>
          <p className="lead mb-4">
            Tenemos un área de contratación. Al crear proyectos y participar con
            la comunidad, puedes ampliar tus posibles oportunidades de empleo.
          </p>
          <p>
            <Link to="/auth/register" className="btn btn-primary btn-lg">
              Crear mi cuenta gratis
            </Link>
          </p>
        </div>
        <div className="col-md-5 order-2 mb-2">
          <img
            src={WorkingImg}
            className="img-fluid"
            alt="Working"
            width="100%"
            height="auto"
          />
        </div>
      </div>
    </>
  );
};

export default Freelancers;
