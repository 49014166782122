import React, { useState } from "react";
import Resume from "../../api/Resume";
import ShowAlert from "../commons/ShowAlert";
import ResumePageTitle from "./ResumePageTitle";
import { CloudUpload } from "@material-ui/icons";

const PdfUploader = () => {
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [uploading, setUploading] = useState(false);

  // Manejar la selección del archivo desde el input
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      uploadFile(selectedFile); // Subir archivo automáticamente
    } else {
      alert("Please upload a valid PDF file.");
    }
  };

  // Manejar el evento cuando el archivo es soltado en el área de arrastrar y soltar
  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type === "application/pdf") {
      setFile(droppedFile);
      uploadFile(droppedFile); // Subir archivo automáticamente
    } else {
      alert("Please upload a valid PDF file.");
    }
  };

  // Manejar los eventos de arrastrar sobre el área
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  // Subir el archivo usando fetch al hacer submit
  const uploadFile = async (fileToUpload) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("pdf", fileToUpload);

    const response = Resume.uploadPdf(formData).then((data) => {
      setUploading(false);
      ShowAlert.success("El archivo se ha subido correctamente");
    });
  };

  return (
    <>
      <ResumePageTitle title="Subir currículum" />
      <form className="text-center">
        {/* Área de arrastrar y soltar */}
        <div
          onClick={() => document.getElementById("pdfInput").click()} // Abrir el selector de archivos al hacer clic
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          className={`border border-secondary rounded p-5 cursor-pointer ${
            dragging ? "bg-light" : ""
          }`}
        >
          {file ? (
            <p>
              {uploading ? "Subiendo el archivo, por favor espere" : file.name}
            </p>
          ) : (
            <p className="text-muted">
              <CloudUpload fontSize="large" />
              <br />
              Arrastra o haz click para subir un currículum
            </p>
          )}
          <input
            type="file"
            accept="application/pdf"
            onChange={handleFileChange}
            style={{ display: "none" }}
            disabled={uploading}
            id="pdfInput"
          />
        </div>
      </form>
    </>
  );
};

export default PdfUploader;
