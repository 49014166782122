import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const styles = {
  confirmButtonText: "Aceptar",
  confirmButtonClass: "btn btn-primary",
  cancelButtonText: "Cancelar",
  cancelButtonClass: "btn btn-secondary",
  customClass: "show-alert",
};

const ShowAlert = {
  success,
  info,
  error,
  close,
  confirm,
  blank,
};
export default ShowAlert;

function close() {
  return MySwal.close();
}

function blank(message) {
  return MySwal.fire({
    ...styles,
    html: message,
    icon: "success",
  });
}

function success(message) {
  return MySwal.fire({
    ...styles,
    title: <p class="big-title-2 font-weight-normal">Todo listo!</p>,
    html: message,
    icon: "success",
  });
}

function info(message) {
  return MySwal.fire({
    ...styles,
    title: <p class="big-title-2 font-weight-normal">Info</p>,
    html: message,
    icon: "info",
  });
}

function confirm(message) {
  return MySwal.fire({
    ...styles,
    title: <p class="big-title-2 font-weight-normal">Confirmar</p>,
    html: message,
    icon: "warning",
    showCancelButton: true,
  });
}

function error(message) {
  return MySwal.fire({
    ...styles,
    title: <p class="big-title-2 font-weight-normal">Ops...</p>,
    html: message,
    icon: "error",
  });
}
