import React, { useContext, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Auth from "../../api/Auth";
import { AppContext } from "../context/AppContext";

const Register = () => {
  const history = useHistory();

  const [user, setUser] = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [registerErrors, setRegisterErrors] = useState("");

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    Auth.register(
      e.target.name.value,
      e.target.username.value,
      e.target.email.value,
      e.target.password.value,
      e.target.password.value
    ).then((data) => {
      if (data.status == "error") {
        setRegisterErrors(data.message.error);
      } else {
        setUser((state) => ({ ...state, user: data.user, token: data.token }));
        history.push("/user/dashboard");
      }
      setLoading(false);
    });
  };

  return (
    <div class="row mt-5">
      <div class="col-sm-12 auth-form">
        <form onSubmit={handleSubmit} method="POST">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="exampleInputEmail1">Nombre</label>
                <input type="text" name="name" class="form-control" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="exampleInputEmail1">Usuario</label>
                <input type="text" name="username" class="form-control" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Email</label>
            <input type="email" name="email" class="form-control" />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Contraseña</label>
            <input type="password" name="password" class="form-control" />
          </div>

          {registerErrors.length > 0 && (
            <div class="alert alert-danger" role="alert">
              {registerErrors}
            </div>
          )}

          <div class="form-group">
            <small id="emailHelp" class="form-text text-muted">
              Al registrarte estás aceptando nuestros{" "}
              <Link to="/website/terms">términos y condiciones</Link>.
            </small>
          </div>

          <button
            type="submit"
            class="btn btn-primary btn-block"
            disabled={loading}
          >
            {loading ? (
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Crear cuenta"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
