import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Logo from "../../media/images/holajuniors-logo.png";
import { Face } from "@material-ui/icons";
import Footer from "./Footer";
import Header from "./Header";

const WebsiteLayout = ({ children, ...rest }) => {
  return (
    <div class="wrap">
      <Header />
      <div class="container">
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default WebsiteLayout;
