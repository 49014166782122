import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Badge } from "react-bootstrap";
import { ThumbUp, Bookmark, Delete, OpenInNew } from "@material-ui/icons";
import CommentComments from "./ContentComments";

const ContentModal = ({
  handleClose,
  content,
  handleLike,
  handleFavorite,
  handleDeleteContent,
}) => {
  if (!content) return null;

  return (
    <Modal show={true} onHide={handleClose} centered size="lg">
      <Modal.Body>
        {/* Usuario que creó el contenido */}
        <div className="d-flex align-items-center mb-3">
          <Link to={`/${content.user.username}`}>
            <img
              src={content.user.avatar}
              alt={content.user.name}
              className="rounded-circle"
              style={{ width: "50px", height: "50px", marginRight: "10px" }}
            />
          </Link>
          <div>
            <Link to={`/${content.user.username}`}>
              <h6 className="m-0">{content.user.name}</h6>
              <small>{new Date(content.created_at).toLocaleDateString()}</small>
            </Link>
          </div>
        </div>

        <h3 className="mb-3">{content.title}</h3>

        {/* Descripción */}
        {content.description && <p className="mb-3">{content.description}</p>}

        <div className="mb-3">
          {content.image && content.image.startsWith("http") && (
            <img
              src={content.image}
              alt={content.title}
              className="img-fluid"
              style={{ objectFit: "cover" }}
            />
          )}
        </div>

        {/* Botón para visitar la URL externa */}
        {content.url_imported && (
          <div className="d-flex justify-content-center align-items-center">
            <Button
              variant="primary"
              href={content.url_imported}
              target="_blank"
              rel="noopener noreferrer"
            >
              Visitar <OpenInNew />
            </Button>
          </div>
        )}

        {/* Botones de acción */}
        <div className="d-flex justify-content-between align-items-center mt-4 mb-4 border border-solid border-gray-300 rounded-pill p-2">
          {/* Botón de Like */}
          <Button
            variant={`link ${content.liked ? "text-primary" : "text-dark"}`}
            onClick={() => handleLike(content.id)}
          >
            <ThumbUp className="me-2" style={{ fontSize: "20px" }} />{" "}
            {content.likes_count > 0 && content.likes_count}
          </Button>

          {/* Botón de Guardar */}
          <Button
            variant={`link ${content.favorited ? "text-primary" : "text-dark"}`}
            onClick={() => handleFavorite(content.id)}
          >
            <Bookmark style={{ fontSize: "20px" }} />{" "}
            <span>
              {content.favorites_count > 0 && content.favorites_count}
            </span>
          </Button>

          {content.can_delete && (
            <Button
              variant="link text-dark"
              onClick={() => handleDeleteContent(content.id)}
              className="ml-2"
            >
              <Delete className="me-2" style={{ fontSize: "20px" }} />
            </Button>
          )}
        </div>

        <CommentComments contentId={content.id} />
      </Modal.Body>

      <Modal.Footer>
        <button type="button" onClick={handleClose} class="btn btn-outline">
          Cerrar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContentModal;
