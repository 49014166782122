import React, { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "../context/AppContext";
import { useHistory } from "react-router-dom";
import Config from "../../Config";
import Jobs from "../../api/Jobs";
import JobsPageTitle from "./JobsPageTitle";
import ShowAlert from "../commons/ShowAlert";
import { AddAPhoto, CheckBox, Launch } from "@material-ui/icons";
import Select from "react-select";

const ErrorFeedback = ({ feedback, show }) => {
  if (show) {
    return <div className="invalid-feedback d-block">{feedback}</div>;
  } else {
    return "";
  }
};

const CreateJob = () => {
  const history = useHistory();
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const [formCountries, setFormCountries] = useState([]);
  const [formSkills, setFormSkills] = useState([]);
  const [formTypeOfJob, setFormTypeOfJob] = useState([]);
  const [formSeniorities, setFormSeniorities] = useState([]);

  const [job, setJob] = useState({
    approved: false,
    country_id: null,
    type_of_job_id: null,
    seniority_id: null,
    skills_ids: "",
    company_name: "",
    url_to_apply: "",
    salary: "",
    title: "",
    description: "",
  });

  useEffect(() => {
    Jobs.getMetadata().then((response) => {
      let skills = response.data.skills.map((skill) => {
        skill.value = skill.id;
        skill.label = skill.name;
        return skill;
      });
      let typesofjob = response.data.typesofjob.map((job) => {
        job.value = job.id;
        job.label = job.name;
        return job;
      });
      let seniorities = response.data.seniorities.map((seniority) => {
        seniority.value = seniority.id;
        seniority.label = seniority.name;
        return seniority;
      });
      let countries = response.data.countries.map((country) => {
        country.value = country.id;
        country.label = country.name;
        return country;
      });
      setFormSkills(skills);
      setFormTypeOfJob(typesofjob);
      setFormSeniorities(seniorities);
      setFormCountries(countries);
    });
  }, []);

  const handleSubmit = (e) => {
    setErrors("");
    setLoading(true);
    e.preventDefault();

    Jobs.createOrUpdate(job).then((data) => {
      if (data.status === "error") {
        setErrors(data.message.error);
        ShowAlert.error("Hemos encontrado errores");
      } else {
        ShowAlert.success("Empleo publicado").then(() => {
          history.push("/jobs/index");
        });
      }
      setLoading(false);
    });
  };

  const updateInput = (e) => {
    setJob({ ...job, [e.target.name]: e.target.value });
  };

  const updateReactSelect = (name, selected) => {
    const allSelectedOptions = Array.isArray(selected)
      ? selected.map((currentValue) => currentValue.id).join(", ")
      : selected.id;
    setJob({ ...job, [name]: allSelectedOptions });
  };

  return (
    <>
      <JobsPageTitle title="Publicar un empleo" />

      <div className="row pt-3 pb-2 mb-3 update-profile">
        <div className="col-md-6 offset-md-3">
          <form onSubmit={handleSubmit} method="POST">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>Título *</label>
                  <input
                    disabled={loading}
                    type="text"
                    className="form-control"
                    name="title"
                    value={job.title}
                    onChange={updateInput}
                  />
                  <ErrorFeedback feedback={errors.title} show={errors.title} />
                </div>
              </div>
            </div>

            {/* Agregar los demás campos del formulario aquí */}
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>Nombre de la Compañía *</label>
                  <input
                    disabled={loading}
                    type="text"
                    className="form-control"
                    name="company_name"
                    value={job.company_name}
                    onChange={updateInput}
                  />
                  <ErrorFeedback
                    feedback={errors.company_name}
                    show={errors.company_name}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>Sueldo</label>
                  <input
                    disabled={loading}
                    type="text"
                    className="form-control"
                    name="salary"
                    value={job.salary}
                    onChange={updateInput}
                  />
                  <ErrorFeedback
                    feedback={errors.salary}
                    show={errors.salary}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>Descripción *</label>
                  <textarea
                    disabled={loading}
                    className="form-control"
                    name="description"
                    onChange={updateInput}
                    value={job.description}
                    rows="12"
                  ></textarea>
                  <ErrorFeedback
                    feedback={errors.description}
                    show={errors.description}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>País</label>
                  {formCountries.length > 0 && (
                    <Select
                      options={formCountries}
                      className="react-select"
                      classNamePrefix="react-select"
                      defaultValue={formCountries.filter(
                        (country) => country.id == job.country_id
                      )}
                      onChange={(value) =>
                        updateReactSelect("country_id", value)
                      }
                    />
                  )}
                  <ErrorFeedback
                    feedback={errors.country_id}
                    show={errors.country_id}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>Modalidad de trabajo *</label>
                  {job && formTypeOfJob.length && (
                    <Select
                      options={formTypeOfJob}
                      className="react-select"
                      classNamePrefix="react-select"
                      defaultValue={formTypeOfJob.filter(
                        (jobType) => jobType.id == job.type_of_job_id
                      )}
                      onChange={(value) =>
                        updateReactSelect("type_of_job_id", value)
                      }
                    />
                  )}
                  <ErrorFeedback
                    feedback={errors.type_of_job_id}
                    show={errors.type_of_job_id}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>Experiencia *</label>
                  {job && formSeniorities.length && (
                    <Select
                      options={formSeniorities}
                      className="react-select"
                      classNamePrefix="react-select"
                      defaultValue={formSeniorities.filter(
                        (seniority) => seniority.id == job.seniority_id
                      )}
                      onChange={(value) =>
                        updateReactSelect("seniority_id", value)
                      }
                    />
                  )}
                  <ErrorFeedback
                    feedback={errors.seniority_id}
                    show={errors.seniority_id}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>Conocimientos y aptitudes *</label>
                  {job && formSkills.length && (
                    <Select
                      options={formSkills}
                      isMulti
                      className="react-select"
                      classNamePrefix="react-select"
                      name="skills"
                      defaultValue={
                        job.skills_ids &&
                        formSkills.filter((skill) =>
                          job.skills_ids
                            .split(",")
                            .map((str) => Number(str))
                            .includes(skill.value)
                        )
                      }
                      onChange={(value) =>
                        updateReactSelect("skills_ids", value)
                      }
                    />
                  )}
                  <ErrorFeedback
                    feedback={errors.skills_ids}
                    show={errors.skills_ids}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>URL para aplicar al puesto *</label>
                  <input
                    disabled={loading}
                    type="text"
                    className="form-control"
                    name="url_to_apply"
                    value={job.url_to_apply}
                    onChange={updateInput}
                  />
                  <ErrorFeedback
                    feedback={errors.url_to_apply}
                    show={errors.url_to_apply}
                  />
                </div>
              </div>
            </div>

            {/* Botón de envío */}
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
            >
              {loading ? "Guardando..." : "Guardar cambios"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateJob;
