import React, { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "../context/AppContext";
import User from "../../api/User";
import { AddAPhoto } from "@material-ui/icons";
import AccountPageTitle from "./AccountPageTitle";
import ShowAlert from "../commons/ShowAlert";

const ChangePassword = () => {
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    const { target: form } = e;
    e.preventDefault();
    setLoading(true);
    User.changePassword(
      form.password_old.value,
      form.password.value,
      form.password_confirmation.value
    ).then((data) => {
      if (data.status == "error") {
        setErrors(data.message.error);
      } else {
        setErrors("");
        form.reset();
        ShowAlert.success("Contraseña actualizada");
      }
      setLoading(false);
    });
  };

  return (
    <>
      <AccountPageTitle title="Contraseña" />

      <div class="row row pt-3 pb-2 mb-3 update-profile">
        <div class="col-md-4 offset-md-4">
          <form onSubmit={handleSubmit} method="POST">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Contraseña actual</label>
                  <input
                    type="password"
                    class="form-control"
                    name="password_old"
                    disabled={loading}
                  />
                </div>
                <div class="form-group">
                  <label>Nueva contraseña</label>
                  <input
                    type="password"
                    class="form-control"
                    name="password"
                    disabled={loading}
                  />
                </div>
                <div class="form-group">
                  <label>Repite la nueva contraseña</label>
                  <input
                    type="password"
                    class="form-control"
                    name="password_confirmation"
                    disabled={loading}
                  />
                </div>
              </div>
            </div>

            {errors.length > 0 && (
              <div class="alert alert-danger" role="alert">
                {errors}
              </div>
            )}

            <button
              type="submit"
              disabled={loading}
              class="btn btn-primary btn-block"
            >
              {loading ? "Guardando..." : "Modificar contraseña"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
