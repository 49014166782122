import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Card, Row, Col, Button } from "react-bootstrap";
import { ThumbUp, Comment, Bookmark } from "@material-ui/icons";
import ContentModal from "./ContentModal"; // Importar el componente del modal
import Content from "../../api/Content";

const ContentsList = () => {
  const history = useHistory();
  const { slug } = useParams();
  const [contents, setContents] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null); // Contenido seleccionado para el modal
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    current_page: 1,
    last_page: 1,
  });

  const handleOpenModal = (content) => {
    setSelectedContent(content);
    if (content) {
      history.push(`/content/${content.slug}`); // Actualizar la URL con el slug
    }
  };

  // Cerrar el modal y restablecer la URL
  const handleCloseModal = () => {
    setSelectedContent(null);
    history.push("/contents/index"); // Redirigir a la página principal
  };

  const handleFavorite = (contentId) => {
    const content = contents.find((content) => content.id === contentId);
    if (!content) return;
    const isFavorited = content.favorited;

    Content.favorite(content.id, isFavorited).then((data) => {
      if (data.status === "ok") {
        const newContents = contents.map((item) =>
          item.id === contentId
            ? {
                ...item,
                favorites_count: isFavorited
                  ? item.favorites_count - 1
                  : item.favorites_count + 1, // Actualizamos el contador de likes
                favorited: !isFavorited, // Cambiar el estado de favorited
              }
            : item
        );
        setContents(newContents);
        if (selectedContent?.id === contentId) {
          setSelectedContent(newContents.find((item) => item.id === contentId));
        }
      }
    });
  };

  const handleDeleteContent = async (contentId) => {
    if (window.confirm("¿Seguro deseas eliminar este contenido?")) {
      handleOpenModal(null);
      setContents((prevContents) =>
        prevContents.filter((content) => content.id !== contentId)
      );
      Content.deleteContent(contentId).finally(() => {
        history.push("/contents/index");
      });
    }
  };

  const handleLike = (contentId) => {
    const content = contents.find((content) => content.id === contentId);
    if (!content) return;

    const isLiked = content.liked; // Verificar si ya está likeado

    Content.like(content.id, isLiked)
      .then((data) => {
        if (data.status === "ok") {
          // Actualizamos el estado de todos los contenidos de una vez
          const newContents = contents.map((item) =>
            item.id === contentId
              ? {
                  ...item,
                  likes_count: isLiked
                    ? item.likes_count - 1
                    : item.likes_count + 1, // Actualizamos el contador de likes
                  liked: !isLiked, // Cambiar el estado de liked
                }
              : item
          );
          setContents(newContents);
          if (selectedContent?.id === contentId) {
            setSelectedContent(
              newContents.find((item) => item.id === contentId)
            );
          }
        } else {
          console.error("Error en la respuesta del servidor:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error al intentar procesar el like:", error);
      });
  };

  const loadContents = (page = 1) => {
    setLoading(true);
    Content.search(page, setContents, setPagination, setLoading, setError);
  };

  useEffect(() => {
    loadContents();
    if (slug) {
      Content.get(slug)
        .then((data) => {
          if (data.status === "ok" && data?.data) {
            console.log(data?.data, "la data");
            setSelectedContent(data?.data);
          }
        })
        .catch((error) => {
          console.log(error);
          history.push("/contents/index");
        });
    }
  }, []);

  const handleLoadMore = () => {
    if (pagination.current_page < pagination.last_page) {
      loadContents(pagination.current_page + 1);
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container">
      <div class="row big-intro">
        <div class="col-sm-12 col-md-8 offset-md-2 text-center">
          <Link to="/create/content">
            <button className="btn btn-primary btn-lg">
              Publicar un contenido
            </button>
          </Link>
        </div>
      </div>
      <Row>
        {contents.map((content) => (
          <Col key={content.id} sm={12} md={6} lg={4} className="mb-4">
            <div className="card h-100">
              {/* Avatar and User Info */}
              <Card.Header className="d-flex align-items-center">
                <Link to={`/${content.user.username}`}>
                  <img
                    src={content.user.avatar}
                    alt="User Avatar"
                    className="rounded-circle"
                    style={{
                      width: "40px",
                      height: "40px",
                      marginRight: "10px",
                    }}
                  />
                </Link>
                <div>
                  <h6 className="m-0">
                    <Link to={`/${content.user.username}`}>
                      {content.user.name}
                    </Link>
                  </h6>
                  <small>
                    {new Date(content.created_at).toLocaleDateString()}
                  </small>
                </div>
              </Card.Header>

              {/* Content Image and Title */}
              <div
                className="card-body cursor-pointer pb-0"
                onClick={() => handleOpenModal(content)}
              >
                <h5 className="text-truncate">
                  <strong>{content.title}</strong>
                </h5>
                <div className="content-image mb-3">
                  {content.image && content.image.startsWith("http") && (
                    <img
                      src={content.image}
                      alt="Content"
                      className="img-fluid w-100"
                      style={{ maxHeight: "200px", objectFit: "cover" }}
                    />
                  )}
                </div>
              </div>

              {/* Footer with Likes and Comments */}
              <Card.Footer className="d-flex justify-content-between align-items-center">
                <Button
                  variant="link"
                  style={{
                    color: content.liked ? "blue" : "black", // Cambia a azul si ya dio like
                    padding: "0",
                    cursor: "pointer",
                  }}
                  onClick={() => handleLike(content.id)}
                >
                  <ThumbUp style={{ fontSize: "20px" }} />{" "}
                  <span>{content.likes_count > 0 && content.likes_count}</span>
                </Button>

                <Button variant="link" className="text-dark p-0 ml-1">
                  <Comment style={{ fontSize: "20px" }} />{" "}
                  {
                    <span>
                      {content.comments_count > 0 && content.comments_count}
                    </span>
                  }
                </Button>
                <Button
                  variant="link"
                  style={{
                    color: content.favorited ? "blue" : "black", // Cambia a azul si ya dio like
                    padding: "0",
                    cursor: "pointer",
                  }}
                  onClick={() => handleFavorite(content.id)}
                >
                  <Bookmark style={{ fontSize: "20px" }} />{" "}
                  <span>
                    {content.favorites_count > 0 && content.favorites_count}
                  </span>
                </Button>
              </Card.Footer>
            </div>
          </Col>
        ))}
      </Row>

      {/* Botón de "Ver más" */}
      {pagination.current_page < pagination.last_page && (
        <div className="text-center my-4">
          <Button variant="primary" onClick={handleLoadMore} disabled={loading}>
            {loading ? "Cargando..." : "Ver más"}
          </Button>
        </div>
      )}

      {selectedContent && (
        <ContentModal
          handleClose={handleCloseModal}
          content={selectedContent}
          handleLike={handleLike}
          handleFavorite={handleFavorite}
          handleDeleteContent={handleDeleteContent}
        />
      )}
    </div>
  );
};

export default ContentsList;
