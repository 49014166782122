import React, { useContext, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Auth from "../../api/Auth";
import { AppContext } from "../context/AppContext";

const Login = () => {
  const history = useHistory();

  const [user, setUser] = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [loginErrors, setLoginErrors] = useState("");

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    Auth.login(e.target.email.value, e.target.password.value).then((data) => {
      if (data.status == "error") {
        setLoginErrors(data.message.error);
      } else {
        setUser((state) => ({ ...state, user: data.user, token: data.token }));
        history.push("/user/dashboard");
      }
      setLoading(false);
    });
  };

  return (
    <div class="row mt-5">
      <div class="col-sm-12 auth-form">
        <form onSubmit={handleSubmit} method="POST">
          <div class="form-group">
            <label for="exampleInputEmail1">Email</label>
            <input type="text" name="email" class="form-control" />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Contraseña</label>
            <input type="password" name="password" class="form-control" />
          </div>

          {loginErrors.length > 0 && (
            <div class="alert alert-danger" role="alert">
              {loginErrors}
            </div>
          )}

          <div class="form-group">
            <button
              type="submit"
              class="btn btn-primary btn-block"
              disabled={loading}
            >
              {loading ? (
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Iniciar sesión"
              )}
            </button>
          </div>

          <div class="form-group mt-5 text-center">
            <small id="emailHelp" class="form-text text-muted">
              Olvidaste tu contraseña?{" "}
              <Link to="/auth/recovery">Ingresa aquí</Link>
            </small>
          </div>
          <div class="form-group text-center">
            <small id="emailHelp" class="form-text text-muted">
              No tienes cuenta aún? <Link to="/auth/register">Registrate</Link>
            </small>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
