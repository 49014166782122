import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton from "react-loading-skeleton";
import Text from "../commons/Text";
import FreelancerModal from "./FreelancerModal";

const FreelanceCard = ({ user }) => {
  const [showModal, setShowModal] = useState(false);
  const [userSelected, setUserSelected] = useState({});

  const handleClose = () => {
    setShowModal(false);
    setUserSelected({});
  };

  const handleShow = (selectedUser) => {
    setShowModal(true);
    setUserSelected(selectedUser);
  };

  return (
    <>
      <div
        className="card text-center mb-3 cursor-pointer card-img-gray"
        onClick={() => handleShow(user)}
      >
        <div className="d-block pt-3">
          <LazyLoadImage
            src={user.avatar}
            className="card-img-top w-50 rounded-circle"
          />
        </div>
        <div className="card-body">
          <h5 className="card-title text-truncate">
            <strong>{user.profile_title}</strong>
          </h5>
          <p className="card-text">
            {user.type_of_job && user.type_of_job.name}
            <br />
            Exp.: {user.seniority && user.seniority.name}
          </p>
          <p className="card-text text-truncate skill-tags">
            {user.user_skills.length > 0 &&
              user.user_skills.map((skill) => Text.textToBadge(skill.name))}
          </p>
        </div>
      </div>
      <FreelancerModal
        user={userSelected}
        showModal={showModal}
        handleClose={handleClose}
      />
    </>
  );
};

export default FreelanceCard;

export const FreelanceCardLoading = () => {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <h5 className="card-title">
          <Skeleton />
        </h5>
        <p className="card-text">
          <Skeleton count={2} />
        </p>
        <p className="card-text">
          <Skeleton />
        </p>
      </div>
    </div>
  );
};
