import Config from "../Config";
import Auth from "./Auth";

const Jobs = {
  getMetadata,
  search,
  createOrUpdate,
  deleteJob,
};
export default Jobs;

function getMetadata(username) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(Config.API_URL + "/jobs/metadata", requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function search(searchFilters, nextPage = null) {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + Auth.token(),
      "Content-Type": "application/json",
    },
  };

  var url = nextPage ? nextPage : Config.API_URL + "/jobs/search";
  if (searchFilters && !nextPage) {
    const searchParams = new URLSearchParams(searchFilters).toString();
    url += searchParams && "?" + searchParams;
  }

  return fetch(url, requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

async function createOrUpdate(job) {
  try {
    let response;

    if (job.id) {
      // Es una actualización
      response = await fetch(`${Config.API_URL}/jobs/${job.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Auth.token(),
        },
        body: JSON.stringify(job),
      });
    } else {
      // Es una creación
      response = await fetch(Config.API_URL + "/jobs/index", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Auth.token(),
        },
        body: JSON.stringify(job),
      });
    }

    const data = await response.json();
    return data; // Puedes devolver más información según tu necesidad
  } catch (error) {
    console.error("Error en createOrUpdate:", error);
    throw error; // Puedes manejar el error de otra manera si es necesario
  }
}

function deleteJob(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth.token(),
    },
  };

  return fetch(Config.API_URL + "/jobs/" + id, requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}
