import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Launch, MoreHoriz } from "@material-ui/icons";
import { Dropdown } from "react-bootstrap";
import { AppContext } from "../context/AppContext";

const ContentPageTitle = (props) => {
  const [context, setContext] = useContext(AppContext);
  const { pathname } = useLocation();

  return (
    <div class="row page-title">
      <div class="col-6">
        <h1 class="big-title-2">{props.title}</h1>
      </div>
      <div class="col-6 text-right tools-container">
        <Dropdown>
          <Dropdown.Toggle variant="outline">
            <MoreHoriz />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              as={Link}
              to="/contents/index"
              active={pathname == "/contents/index" ? true : false}
            >
              Ver contenidos
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to="/create/content"
              active={pathname == "/create/content" ? true : false}
            >
              Publicar contenido
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
export default ContentPageTitle;
