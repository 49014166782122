import React from "react";
import Text from "../../commons/Text";
import ShowAlert from "../../commons/ShowAlert";
import { Telegram } from "@material-ui/icons";

const copyReadmeTemplate = () => {
  Text.copyText("readme-file");
  ShowAlert.success("El contenido ha sido copiado");
};

const ChallengeHelp = ({ currentChallenge, isVisible }) => {
  return (
    <div className={!isVisible && "d-none"}>
      <p>
        <h2 className="big-title-2">¿Cómo participar?</h2>
      </p>
      <p>
        <strong>1) Crea tu proyecto</strong>
      </p>
      <p>
        Revisa los requerimientos para participar en el desafío. Mayormente
        puedes utilizar tu Stack preferido.
      </p>

      <p>
        <strong>2) Crea un repositorio en GitHub</strong>
      </p>
      <p>
        Requerimos un repositorio público de GitHub para cada solución. De esta
        manera, es más fácil compartir su código con la comunidad si necesita
        ayuda.
      </p>

      <p>
        Asegurate de incluir el archivo README.md en tu repositorio de GitHub.
        Puedes utilizar el siguiente template.
      </p>

      <pre className="challenge-readme-md bg-light p-3">
        <code id="readme-file">
          {`# HolaJuniors - ${currentChallenge.title}
Desafío de programación de [HolaJuniors](https://holajuniors.com).

Nuestros desafíos te ayudan a mejorar tus habilidades de programación mediante la creación de proyectos realistas.

## Requerimientos del proyecto: [${currentChallenge.title}](https://holajuniors.com/challenges/${currentChallenge.slug})

${currentChallenge.description}

## Stack utilizado
Lenguajes, tecnología y frameworks utilizados en el desarrollo

## Screenshots y vista previa
Screenshots o enlace a la vista previa del trabajo finalizado

## Instalación
Instrucciones para otros desarrolladores sobre cómo instalar y ejecutar tu código en su entorno local.

## Licencia
[MIT](https://choosealicense.com/licenses/mit/)
La mayoría de los proyectos de código abierto utilizan la licencia MIT. Siéntete libre de elegir la licencia que prefieras`}
        </code>
      </pre>
      <p>
        <button className="btn btn-secondary" onClick={copyReadmeTemplate}>
          Copiar README.md
        </button>
      </p>
      <p>
        <strong>3) Comparte tu solución</strong>
      </p>
      <p>
        Envía tu trabajo para compartirlo con la comunidad y recibir feedback si
        lo deseas
      </p>

      <p>
        <h2 className="big-title-2">¿Neceitas más ayuda?</h2>
      </p>
      <p>
        <a
          class="btn btn-outline"
          href="https://t.me/holajuniors"
          target="_blank"
        >
          <Telegram /> únete al grupo de <strong>Telegram</strong> y pide ayuda
          a la comunidad.
        </a>
      </p>
    </div>
  );
};
export default ChallengeHelp;
