import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "@material-ui/icons";
import Logo from "../../media/images/holajuniors-logo.png";
import { useEventCallback } from "@material-ui/core";
import { Dropdown } from "react-bootstrap";

const Header = (props) => {
  return (
    <div id="header">
      <nav class="navbar navbar-expand-md navbar-light">
        <Link class="navbar-brand" to="/">
          <img src={Logo} class="logo-website" />
        </Link>
        <div class="ml-auto">
          <ul class="navbar-nav d-inline align-middle">
            <li class="nav-item  d-none d-md-inline float-left">
              <Link class="nav-link nav-link-web" to="/challenges/search">
                Desafíos
              </Link>
            </li>
            <li class="nav-item ml-4 d-none d-md-inline float-left">
              <Link class="nav-link nav-link-web" to="/solutions/search">
                Soluciones
              </Link>
            </li>
            <li class="nav-item ml-4 float-left d-none d-md-inline">
              <Link class="nav-link nav-link-web" to="/search/people">
                Programadores
              </Link>
            </li>
            <li class="nav-item ml-4 float-left d-none d-md-inline">
              <Link class="nav-link nav-link-web" to="/jobs/search">
                Empleo Juniors
              </Link>
            </li>

            {/* <li class="nav-item ml-4 ml-4  d-none d-md-inline float-left">
              <Link class="nav-link nav-link-web" to="/website/business">
                Soy empresa
              </Link>
            </li> */}
            <li class="nav-item  d-none d-md-inline float-left">
              <Link class="nav-link ml-4 nav-link-web" to="/user/dashboard">
                Mi cuenta
              </Link>
            </li>
            <li className="nav-item-ml-4 ml-4  d-block d-md-none float-left">
              <Dropdown>
                <Dropdown.Toggle variant="secondary">
                  <Menu />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/challenges/search">
                    Desafíos
                  </Dropdown.Item>

                  <Dropdown.Item as={Link} to="/solutions/search">
                    Soluciones
                  </Dropdown.Item>

                  <Dropdown.Item as={Link} to="/search/people">
                    Programadores
                  </Dropdown.Item>

                  <Dropdown.Item as={Link} to="/jobs/search">
                    Empleo Juniors
                  </Dropdown.Item>

                  {/* <Dropdown.Item as={Link} to="/website/business">
                    Soy empresa
                  </Dropdown.Item> */}

                  <Dropdown.Item as={Link} to="/user/dashboard">
                    Mi cuenta
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
