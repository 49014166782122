import React, { useState, useContext, useEffect } from "react";
import Text from "../commons/Text";
import { AppContext } from "../context/AppContext";

const SolutionComments = ({
  solution,
  createSolutionResponse,
  comments,
  deleteComment,
  write,
  writingComment,
}) => {
  const [context, setContext] = useContext(AppContext);
  const [hideSolution, setHideSolution] = useState(false);

  return (
    <div>
      {!hideSolution &&
        comments?.length > 0 &&
        comments.map((comment) => {
          return (
            <div
              className={`card-body media bg-light mt-3 ${
                comment.comment_parent_id && "ml-5"
              }`}
            >
              <img
                src={comment?.user.avatar}
                className="rounded-circle mr-3"
                width="29px"
              />
              <div className="media-body">
                <strong>{comment?.user.username}</strong>
                <p>{Text.nl2br(comment.comment)}</p>

                <ul class="nav">
                  {!comment.comment_parent_id && (
                    <li class="nav-item">
                      <a
                        class="nav-link pl-0 text-secondary"
                        href="#"
                        onClick={(e) => write(e, solution.id, comment.id)}
                      >
                        <small>Comentar {comment.comment_parent_id}</small>
                      </a>
                    </li>
                  )}

                  {context.user?.id === comment?.user.id && (
                    <li class="nav-item">
                      <a
                        class="nav-link pl-0 text-secondary"
                        href="#"
                        onClick={(e) => {
                          deleteComment(e, comment.id);
                        }}
                      >
                        <small>Eliminar</small>
                      </a>
                    </li>
                  )}
                </ul>

                {writingComment === comment.id && !comment.comment_parent_id && (
                  <form onSubmit={createSolutionResponse}>
                    <div class="form-group">
                      <label></label>
                      <input
                        type="hidden"
                        name="comment_parent_id"
                        value={comment.id}
                      />
                      <textarea
                        name="comment"
                        class="form-control bg-light mb-2"
                        rows="4"
                      ></textarea>
                      <button className="btn btn-primary">
                        Enviar respuesta
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default SolutionComments;
