import React, { useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory, Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Text from "../commons/Text";
import Money from "../commons/Money";
import UserApi from "../../api/User";
import {
  WorkOutline,
  Laptop,
  Public,
  Code,
  OpenInNew,
  LocationOn,
  MailOutline,
  LinkedIn,
  GitHub,
  Phone,
} from "@material-ui/icons";
import ShowAlert from "../commons/ShowAlert";

const FreelancerModal = (props) => {
  const history = useHistory();
  const [context, setContext] = useContext(AppContext);

  const doContact = async () => {
    const user = await UserApi.createcontact(props.user.id);
    if (user.status == "error") {
      ShowAlert.error(user.message.error);
    } else {
      ShowAlert.success("Agregado a tus contactos");
    }
  };

  const contactAction = async (contact_user_id) => {
    if (!context.user) {
      window.open("/user/credits", "_blank");
    } else {
      ShowAlert.confirm(
        "Vas a consumir 1 crédito para desbloquear todos los datos de contacto del usuario."
      ).then((response) => {
        if (response.isConfirmed) {
          doContact();
        }
      });
    }
  };

  return (
    <Modal show={props.showModal === true} onHide={props.handleClose} size="md">
      <Modal.Body>
        {props.user && (
          <div className="text-break">
            <div class="mb-3 text-center p-5 bg-light">
              <img src={props.user.avatar} class="w-50 rounded-circle mb-3" />
              <h4 className="big-title-profile">
                {props.user && props.user.profile_title}
              </h4>
              {props.user.available_to_work && (
                <div className="mb-3">Disponible para trabajar</div>
              )}

              {/* {!props.isContact && (
                <button
                  className="btn btn-primary btn-block mb-2"
                  onClick={contactAction}
                >
                  Contactar
                </button>
              )} */}

              <Link
                to={`/${props.user?.username}`}
                target="_blank"
                className="btn btn-primary btn-block"
              >
                Ver perfil completo
              </Link>
            </div>

            <ul className="public-profile-info mb-4">
              {props.user?.seniority && (
                <li>
                  <WorkOutline /> <span>{props.user.seniority.name}</span>
                </li>
              )}
              {props.user?.type_of_job && (
                <li>
                  <Laptop /> <span>{props.user.type_of_job.name}</span>
                </li>
              )}
              {props.user?.country && (
                <li>
                  <LocationOn />
                  <span>{props.user.country.name}</span>
                </li>
              )}

              {props.user?.phone_number && (
                <li>
                  <Phone />
                  <span>{props.user.phone_number}</span>
                </li>
              )}

              {props.user?.email && (
                <li>
                  <MailOutline />
                  <span>
                    <a href={`mailto:${props.user.email}`} target="_blank">
                      {props.user.email}
                    </a>
                  </span>
                </li>
              )}

              {props.user?.website_url && (
                <li>
                  <Public />
                  <span>
                    <a href={props.user.website_url} target="_blank">
                      {props.user.website_url}
                    </a>
                  </span>
                </li>
              )}

              {props.user?.linkedin_url && (
                <li>
                  <LinkedIn />
                  <span>
                    <a href={props.user.linkedin_url} target="_blank">
                      {props.user.linkedin_url}
                    </a>
                  </span>
                </li>
              )}

              {props.user?.github_url && (
                <li>
                  <GitHub />
                  <span>
                    <a href={props.user.github_url} target="_blank">
                      {props.user.github_url}
                    </a>
                  </span>
                </li>
              )}
            </ul>

            <div className="skill-tags mb-4">
              {props.user.user_skills &&
                props.user.user_skills.map((skill) => {
                  return Text.textToBadge(skill.name);
                })}
            </div>

            <h3 className="big-title-2">Sobre mí</h3>
            {props.user.bio && Text.nl2br(props.user.bio)}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          onClick={props.handleClose}
          class="btn btn-outline"
        >
          Cerrar
        </button>
        {/* {!props.isContact && (
          <button className="btn btn-primary" onClick={contactAction}>
            Contactar
          </button>
        )} */}
      </Modal.Footer>
    </Modal>
  );
};
export default FreelancerModal;
