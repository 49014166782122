import React, { useContext, useState } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import Auth from '../../api/Auth'
import {AppContext} from '../context/AppContext'

const Reset = (props) => {

  const history = useHistory()
  const { token } = useParams()
  const params = new URLSearchParams(window.location.search)
  const email = params.get('email')

  const [user, setUser] = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [loginErrors, setLoginErrors] = useState('')

  const handleSubmit = (e) => {
      setLoading(true)
      e.preventDefault()
      Auth.reset(e.target.password.value, e.target.password_confirmation.value, email, token)
      .then(data => {
        if (data.status == 'error') {
            setLoginErrors(data.message.error)
        } else {
            history.push("/auth/login")
        }
        setLoading(false)
      })
  }

  return (
    <div class="row mt-5">
      <div class="col-sm-12 auth-form">
          <form onSubmit={handleSubmit} method="POST">
            <div class="form-group">
              <label for="exampleInputPassword1">Nueva contraseña</label>
              <input type="password" name="password" class="form-control" />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Repite la nueva contraseña</label>
              <input type="password" name="password_confirmation" class="form-control" />
            </div>

            {
              loginErrors.length > 0 &&
              <div class="alert alert-danger" role="alert">
                {loginErrors}
              </div>
            }

            <div class="form-group">
              <button
                type="submit"
                class="btn btn-primary btn-block"
                disabled={loading}
                >
                {
                  loading
                  ? <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  : 'Crear contraseña'
                }
              </button>
            </div>
          </form>
      </div>
    </div>
  )
}

export default Reset
