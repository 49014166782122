import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { WorkOutline, Laptop, Public, Code } from "@material-ui/icons";
import ShowAlert from "../commons/ShowAlert";
import Config from "../../Config";
import Payment from "../../api/Payment";
import UserApi from "../../api/User";

import WebDevelopmentImg from "../../media/images/web/business/web-development-bw.png";
import WithLaptopImg from "../../media/images/web/business/programming-laptop.png";
import ProgrammingImg from "../../media/images/web/business/programming.png";
import HolaMail from "../../media/images/web/business/contact-by-email.png";
import SearchProfile from "../../media/images/web/business/search-profiles.png";
import StripeBanner from "../payments/Stripe/StripeBanner";

const Business = () => {
  const history = useHistory();
  const [context, setContext] = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState(null);
  const [userCredits, setUserCredits] = useState(null);

  const pricingCardClass = [
    "card-pricing-one",
    "card-pricing-two",
    "card-pricing-three",
    "card-pricing-four",
  ];

  const checkout = (planId) => {
    if (!context.user) {
      history.push("/auth/register");
    } else {
      history.push("/user/credits");
    }
  };

  const getPlans = async () => {
    const plans = await Payment.plans();
    setPlans(plans.data);
  };

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row d-flex flex-row justify-content-center align-items-center pt-5 mt-5">
          <div className="col-md-6 order-2 order-md-1">
            <h1 className="big-title mb-5">Talento emergente</h1>
            <p className="lead mb-4">
              HolaJuniors te ofrece una amplia selección de{" "}
              <strong className="font-weight-bold">programadores junior</strong>{" "}
              listos para destacar en tu empresa. Desde recién graduados hasta
              profesionales con experiencia probada.
            </p>
            <p>
              <a href="/auth/register" className="btn btn-primary btn-lg">
                Contratar talento
              </a>
            </p>
          </div>
          <div className="col-md-6 order-1 order-md-2 mb-2">
            <img src={WebDevelopmentImg} className="img-fluid" />
          </div>
        </div>

        <div className="row d-flex flex-row justify-content-center align-items-center my-5 py-5">
          <div className="col-md-6 order-md-2">
            <h3 className="pre-big-title">HolaMail</h3>
            <h2 className="big-title mb-3">E-mail masivo</h2>
            <p className="lead">
              <i>
                Envía propuestas de empleo{" "}
                <strong className="font-weight-bold">
                  directamente a la casilla de correo
                </strong>{" "}
                de los talentos que necesitas.
              </i>
            </p>
            <p className="lead">
              No esperes a que los candidatos revisen portales de empleo o se
              postulen activamente, mejor envía ofertas directas a su correo
              electrónico y capta su atención de manera inmediata.
            </p>
            <p>
              <a href="/auth/register" className="btn btn-outline">
                Probar ahora
              </a>
            </p>
          </div>
          <div className="col-md-6 order-md-1">
            <img src={HolaMail} className="img-fluid flip-horizontal" />
          </div>
        </div>

        <div className="row d-flex flex-row justify-content-center align-items-center my-5 py-5">
          <div className="col-md-6 order-md-1">
            <h3 className="pre-big-title">HolaPersonal</h3>
            <h2 className="big-title mb-3">Adquiere el perfil ideal</h2>
            <p className="lead mb-6">
              Realiza una búsqueda y adquiere el contacto. Recibirás todos sus
              datos para que puedas conectar directamente y de forma
              personalizada con los candidatos.
            </p>
            <a href="/auth/register" className="btn btn-outline btn-lg">
              Comenzar una búsqueda
            </a>
          </div>
          <div className="col-md-5 order-md-2">
            <img src={SearchProfile} className="img-fluid flip-horizontal" />
          </div>
        </div>

        <div className="row d-flex flex-row justify-content-center align-items-center my-5 py-5">
          <div className="col-md-6 order-md-2">
            <h2 className="big-title mb-3">Todas las Habilidades</h2>
            <p className="lead">
              <i>React, Python, JAVA, SQL,</i> todo es posible.
            </p>
            <p className="lead">
              Nuestra base de datos te permite filtrar y explorar perfiles en
              función de lenguajes de programación, tecnologías, frameworks y
              otras competencias clave.
            </p>
            <p>
              <a href="/auth/register" className="btn btn-outline">
                Probar ahora
              </a>
            </p>
          </div>
          <div className="col-md-6 order-md-1">
            <img src={WithLaptopImg} className="img-fluid" />
          </div>
        </div>

        <div className="row d-flex flex-row justify-content-center align-items-center my-5 py-5">
          <div className="col-md-7 order-md-1">
            <h2 className="big-title mb-3">Empleados o Freelancers</h2>
            <p className="lead mb-4">
              HolaJuniors te permite elegir profesionales a tiempo completo para
              establecer un equipo interno o freelancers que pueden aportar su
              experiencia de manera flexible.
            </p>
            <a href="/auth/register" className="btn btn-outline btn-lg">
              Comenzar una búsqueda
            </a>
          </div>
          <div className="col-md-5 order-md-2">
            <img src={ProgrammingImg} className="img-fluid" />
          </div>
        </div>
      </div>

      <div className="mt-5 pt-5 mb-3">
        <h2 className="big-title">Pack de créditos</h2>
        <div className="mb-5">
          <strong>HolaMail</strong>: Se descontará 1 crédito por cada 5 emails
          que envíes.
          <br />
          <strong>HolaPersonal</strong>: Se descontará 1 crédito por cada perfil
          que adquieras directamente.
        </div>
        {!loading && plans && (
          <div className="row">
            {plans.map((plan, index) => {
              return (
                <div className="col-md-4">
                  <div
                    class={`card mb-3 cursor-pointer ${pricingCardClass[index]}`}
                    onClick={() => checkout(plan.id)}
                  >
                    <div class="card-body">
                      <h5 class="card-title">
                        <div class="row">
                          <div className="col-6">
                            <strong>{plan.name}</strong>
                          </div>
                          <div className="col-6 text-right">
                            {plan.currency} {plan.price}
                          </div>
                        </div>
                      </h5>
                      <p class="card-text">
                        {plan.credits} créditos
                        <br />
                        Vigencia de uso: {plan.expiration_in_days} días
                      </p>
                      <p class="card-text">
                        <button
                          onClick={() => checkout(plan.id)}
                          className="btn btn-primary"
                        >
                          Comprar
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="w-100 text-center mb-5 pb-5">
        <StripeBanner />
      </div>

      <div className="row py-5 text-center bg-light">
        <div className="col-md-8 mx-auto py-5">
          <h2 className="big-title font-weight-normal mb-5">
            Conecta con programadores junior hoy mismo
          </h2>
          <a className="btn btn-primary btn-lg" href="/auth/register">
            Crear una cuenta
          </a>{" "}
          <a
            className="btn btn-outline btn-lg"
            href="mailto:holajuniors@pampabit.com"
          >
            Pide asesoría
          </a>
        </div>
      </div>
    </div>
  );
};
export default Business;
