import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton from "react-loading-skeleton";
import Text from "../commons/Text";
import { People } from "@material-ui/icons";

const ChallengeCard = ({ challenge }) => {
  const history = useHistory();

  const goToChallenge = (slug) => {
    history.push("/challenges/" + slug);
  };

  return (
    <div
      class="card mb-3 cursor-pointer h-100"
      onClick={() => goToChallenge(challenge.slug)}
    >
      <div class="card-body">
        <h5 class="card-title">
          <strong className="badge badge-success">
            {challenge.difficulty}
          </strong>
        </h5>
        <h5>
          <strong>{challenge.title}</strong>
        </h5>
        <p class="card-text text-truncate skill-tags">
          {challenge.skills.length > 0 &&
            challenge.skills.map((skill) => {
              return Text.textToBadge(skill.name);
            })}
        </p>
        <p class="card-text text-truncate pb-0 small text-muted">
          <People fontSize="small" />
          <span className="align-middle ml-2">
            {challenge?.solutions > 0
              ? challenge.solutions + " participantes"
              : "Nuevo"}
          </span>
        </p>
      </div>
    </div>
  );
};
export default ChallengeCard;

export const ChallengeCardLoading = () => {
  return (
    <div class="card mb-3">
      <div class="card-body">
        <h5 class="card-title">
          <Skeleton />
        </h5>
        <p class="card-text">
          <Skeleton count={2} />
        </p>
        <p class="card-text">
          <Skeleton />
        </p>
      </div>
    </div>
  );
};
