import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import Challenge from "../../api/Challenge";
import SolutionComments from "./SolutionComments";
import {
  PlayArrow,
  Code,
  ChatBubbleOutline as CommentIcon,
  Delete,
} from "@material-ui/icons";
import { AppContext } from "../context/AppContext";
import ShowAlert from "../commons/ShowAlert";

const ShowSolution = () => {
  const [context, setContext] = useContext(AppContext);
  const [loading, setLoading] = useState();
  const [currentSolution, setCurrentSolution] = useState();
  const { id } = useParams();

  const history = useHistory();
  const [comments, setComments] = useState([]);
  const [writingSolutionResponse, setWritingSolutionResponse] = useState(null);
  const [writingComment, setWritingComment] = useState(null);

  const deleteSolution = (e, solution_id) => {
    e.preventDefault();
    ShowAlert.confirm("Se eliminará tu solución y sus comentarios").then(
      (response) => {
        if (response.isConfirmed) {
          Challenge.deleteSolution(solution_id).then(() =>
            history.push("/challenges/" + currentSolution.challenge.slug)
          );
        }
      }
    );
  };

  const createSolutionResponse = (e) => {
    e.preventDefault();
    setWritingSolutionResponse(null);
    setWritingComment(null);

    Challenge.createComment(
      currentSolution.id,
      e.target?.comment?.value,
      e.target?.comment_parent_id?.value
    ).then((data) => {
      orderComments([...comments, data.data]);
      ShowAlert.success("Comentario enviado correctamente");
    });
  };

  const deleteComment = (e, comment_id) => {
    e.preventDefault();
    ShowAlert.confirm("Se eliminará tu comentario").then((response) => {
      if (response.isConfirmed) {
        Challenge.deleteComment(comment_id).then(() => {
          let newComments = comments.filter((c) => c.id !== comment_id);
          setComments(newComments);
        });
      }
    });
  };

  const write = (e, solution_id, comment_id = null) => {
    e.preventDefault();
    if (!context.user) {
      history.push("/auth/login");
    }

    if (comment_id) {
      setWritingComment(comment_id);
    } else {
      setWritingSolutionResponse(solution_id);
    }
  };

  const orderComments = (commentToOrdered) => {
    const parentComments = commentToOrdered.filter((c) => !c.comment_parent_id);

    const orderedComments = [];
    parentComments.map((c) => {
      orderedComments.push(c);
      commentToOrdered.map((cChild) => {
        if (cChild.comment_parent_id == c.id) {
          orderedComments.push(cChild);
        }
      });
    });

    setComments(orderedComments);
  };

  useEffect(() => {
    setLoading(true);
    Challenge.getSolutionById(id)
      .then((data) => {
        if (data.data) {
          setCurrentSolution(data.data);
          orderComments(data.data.comments);
        } else {
          window.location.href = "/";
        }

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="row mb-3">
      {!loading && currentSolution && (
        <div className="col-sm-12 col-md-10 offset-md-1">
          <div className="card mb-4 py-2">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-12">
                  <p className="mb-0">
                    <img
                      src={currentSolution?.user.avatar}
                      className="rounded-circle mr-2"
                      width="29px"
                    />
                    Solución de{" "}
                    <a
                      href={`/${currentSolution.user.username}`}
                      className="text-primary"
                    >
                      @{currentSolution.user.username}
                    </a>{" "}
                    a:
                  </p>
                  <h1 className="big-title mb-4">
                    <a href={`/challenges/${currentSolution.challenge.slug}`}>
                      {currentSolution.challenge.title}
                    </a>
                  </h1>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-7">
                  <p>{currentSolution.description}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <ul class="nav ml-0 pl-0">
                    <li class="nav-item">
                      <small>
                        <a
                          class="btn btn-outline"
                          href={currentSolution.repository_url}
                          target="_blank"
                        >
                          <Code />{" "}
                          <span className="d-none d-md-inline">Código</span>
                        </a>
                      </small>
                    </li>
                    {currentSolution.demo_url && (
                      <li class="nav-item">
                        <small>
                          <a
                            class="nav-link text-secondary"
                            href={currentSolution.demo_url}
                            target="_blank"
                          >
                            <PlayArrow />{" "}
                            <span className="d-none d-md-inline">Demo</span>
                          </a>
                        </small>
                      </li>
                    )}

                    <li class="nav-item">
                      <a
                        class="nav-link text-secondary"
                        href="#"
                        onClick={(e) => write(e, currentSolution.id, null)}
                      >
                        <small>
                          <CommentIcon />{" "}
                          <span className="d-none d-md-inline">Comentar</span>
                        </small>
                      </a>
                    </li>
                    {context.user?.id === currentSolution.user_id && (
                      <li class="nav-item">
                        <a
                          class="nav-link text-secondary"
                          href="#"
                          onClick={(e) => {
                            deleteSolution(e, currentSolution.id);
                          }}
                        >
                          <small>
                            <Delete />{" "}
                            <span className="d-none d-md-inline">Eliminar</span>
                          </small>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>

              {writingSolutionResponse === currentSolution.id && (
                <form onSubmit={createSolutionResponse}>
                  <div class="form-group">
                    <label></label>
                    <textarea
                      name="comment"
                      class="form-control bg-light mb-2"
                      rows="4"
                    ></textarea>
                    <button className="btn btn-primary">
                      Enviar respuesta
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>

          <SolutionComments
            currentChallenge={currentSolution.challenge}
            solution={currentSolution}
            createSolutionResponse={createSolutionResponse}
            deleteComment={deleteComment}
            comments={comments}
            setComments={setComments}
            write={write}
            writingComment={writingComment}
            setWritingComment={setWritingComment}
          />
        </div>
      )}
    </div>
  );
};
export default ShowSolution;
