import React, { useContext, useState, useEffect } from "react";
import User from "../../api/User";
import Jobs from "../../api/Jobs";
import FreelanceCard, { FreelanceCardLoading } from "../user/FreelanceCard";
import SendProposalModal from "./SendProposalModal";
import Select from "react-select";

const SearchPeople = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userSelected, setUserSelected] = useState({});

  const [searchFilters, setSearchFilters] = useState({});
  const [formCountries, setFormCountries] = useState([]);
  const [formSkills, setFormSkills] = useState([]);
  const [formTypeOfJob, setFormTypeOfJob] = useState([]);
  const [formSeniorities, setFormSeniorities] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [nextPage, setNextPage] = useState(null);
  const [totalResults, setTotalResults] = useState(0);

  const handleClose = () => {
    setShowModal(false);
    setUserSelected({});
  };
  const handleShow = (user) => {
    setShowModal(true);
    setUserSelected(user);
  };

  const doSearchPeople = (params = null, loadMore = false, page = false) => {
    setLoading(true);
    User.searchPeople(params, page).then((newUsers) => {
      if (newUsers.status == "ok" && newUsers.data.total) {
        if (loadMore) {
          setUsers([...users, ...newUsers.data.data]);
        } else {
          setUsers(newUsers.data.data);
        }
        setNextPage(newUsers?.data?.next_page_url || null);
        setTotalResults(newUsers?.data?.total);
      } else {
        setUsers([]);
        setNextPage(null);
        setTotalResults(0);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    doSearchPeople();

    Jobs.getMetadata().then((response) => {
      let skills = response.data.skills.map((skill) => {
        skill.value = skill.id;
        skill.label = skill.name;
        return skill;
      });
      let typesofjob = response.data.typesofjob
        .map((job) => {
          job.value = job.id;
          job.label = job.name;
          return job;
        })
        .filter((job) => job.id != 4);
      let seniorities = response.data.seniorities.map((seniority) => {
        seniority.value = seniority.id;
        seniority.label = seniority.name;
        return seniority;
      });
      let countries = response.data.countries.map((country) => {
        country.value = country.id;
        country.label = country.name;
        return country;
      });

      setFormSkills(skills);
      setFormTypeOfJob(typesofjob);
      setFormSeniorities(seniorities);
      setFormCountries(countries);
    });
  }, []);

  const updateReactSelect = (name, selected) => {
    const allSelectedOptions = selected
      ? Array.isArray(selected)
        ? selected.map((currentValue) => currentValue.id).join(", ")
        : selected.id
      : null;

    const theFilters = { ...searchFilters, [name]: allSelectedOptions };
    setSearchFilters(theFilters);
    doSearchPeople(theFilters);
  };

  return (
    <>
      <div class="row big-intro">
        <div class="col-sm-12 col-md-8 offset-md-2 text-center">
          <h1 className="big-title mb-4">Buscando programadores</h1>
        </div>
        {!loading && (
          <div className="col-12 text-center my-3 py-2">
            {totalResults ? (
              <strong>{totalResults} devs</strong>
            ) : (
              `No hemos encontrado candidatos`
            )}

            {/* {totalResults && (
              <SendProposalModal
                searchFilters={searchFilters}
                totalResults={totalResults}
              />
            )} */}
          </div>
        )}
      </div>

      <div class="row mb-3 d-block d-md-none">
        <div class="col-md-3">
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="btn btn-block btn-secondary"
          >
            {showFilters ? "Ocultar filtros" : "Refinar búsqueda"}
          </button>
        </div>
      </div>

      <div className={`row d-none d-md-block ${showFilters && "d-block"}`}>
        <div className="col-12">
          <div class="row mb-3 mt-4">
            <div class="col-md-3">
              <div class="form-group">
                {formSkills.length > 0 && (
                  <Select
                    options={formSkills}
                    isClearable
                    className="react-select react-select-light"
                    classNamePrefix="react-select"
                    name="filter_skill"
                    placeholder="Habilidades"
                    onChange={(value) =>
                      updateReactSelect("filter_skill", value)
                    }
                  />
                )}
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                {formSkills.length > 0 && (
                  <Select
                    isClearable
                    options={formSeniorities}
                    className="react-select react-select-light"
                    classNamePrefix="react-select"
                    name="filter_seniority"
                    placeholder="Experiencia"
                    onChange={(value) =>
                      updateReactSelect("filter_seniority", value)
                    }
                  />
                )}
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                {formSkills.length > 0 && (
                  <Select
                    isClearable
                    options={formTypeOfJob}
                    className="react-select react-select-light"
                    classNamePrefix="react-select"
                    name="filter_type_of_job"
                    placeholder="Modalidad"
                    onChange={(value) =>
                      updateReactSelect("filter_type_of_job", value)
                    }
                  />
                )}
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                {formCountries.length > 0 && (
                  <Select
                    isClearable
                    options={formCountries}
                    className="react-select react-select-light"
                    classNamePrefix="react-select"
                    name="filter_country"
                    placeholder="País"
                    onChange={(value) =>
                      updateReactSelect("filter_country", value)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div class="row">
          <div class="col-md-3 fade-in">
            <FreelanceCardLoading />
          </div>
          <div class="col-md-3 fade-in">
            <FreelanceCardLoading />
          </div>
        </div>
      )}

      <div class="row">
        <div class="col-12">
          {users && (
            <div>
              <div class="row">
                {users.map((user) => {
                  return (
                    <div class="col-md-3 fade-in">
                      <FreelanceCard user={user} handleShow={handleShow} />
                    </div>
                  );
                })}
              </div>
              <div class="row my-3">
                <div className="col-12 text-center">
                  {nextPage && (
                    <button
                      onClick={() =>
                        doSearchPeople(searchFilters, true, nextPage)
                      }
                      disabled={loading}
                      className="btn btn-secondary"
                    >
                      {loading ? "Buscando" : "Ver más"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchPeople;
