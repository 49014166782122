import React, { useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import LogoMobile from "../../media/images/holajuniors-logo.png";
import { Code, Menu, Work } from "@material-ui/icons";
import { Face, Search, PermContactCalendar } from "@material-ui/icons";

const AdminLayout = ({ children, ...rest }) => {
  const [showMobileMenu, setShowMobileMenu] = useState();

  return (
    <div class="container-fluid">
      <div class="row d-sm-block d-md-none mobile-header">
        <div class="col-12">
          <ul class="nav w-100 d-flex flex-row text-left justify-content-between">
            <li class="nav-item">
              <Link to="/user/dashboard" className="nav-link">
                <img src={LogoMobile} class="logo-mobile" />
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/jobs/index" className="nav-link">
                <Work />
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/challenges/search" className="nav-link">
                <Code />
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/search/people" className="nav-link">
                <Search />
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/user/account" className="nav-link">
                <Face />
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div class="row">
        <Sidebar showMobileMenu={showMobileMenu} />
        <main
          role="main"
          class="col-md-9 ml-sm-auto col-lg-10 px-md-4 pt-3 pb-2 mb-3"
        >
          {children}
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
