import React, { useContext, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Text from "../commons/Text";
import UserApi from "../../api/User";
import EmailMessages from "../../api/EmailMessages";
import {
  WorkOutline,
  Laptop,
  Public,
  LocationOn,
  MailOutline,
  LinkedIn,
  GitHub,
  Phone,
} from "@material-ui/icons";
import ShowAlert from "../commons/ShowAlert";

const SendProposalModal = ({ totalResults, searchFilters }) => {
  const history = useHistory();
  const [context, setContext] = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const usersToCredits = Math.ceil(totalResults * 0.2);

  const goToCreditsPage = () => {
    history.push("/user/credits");
  };

  const createMessage = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { subject, body, email_category_id, reply_to } = e.target;
    const message = await EmailMessages.create(
      searchFilters,
      subject.value,
      body.value,
      email_category_id.value,
      reply_to.value
    );
    if (message.status == "error") {
      ShowAlert.error(message.message.error);
      setLoading(false);
    } else {
      history.push("/messages/index");
      setLoading(false);
    }
  };

  const hideModal = () => {
    ShowAlert.confirm("¿Quieres salir del editor?").then((response) => {
      response.isConfirmed && setShowModal(false);
    });
  };

  return (
    <>
      {context?.user?.available_credits &&
      context?.user?.available_credits >= usersToCredits ? (
        <button
          class="btn btn-primary ml-3"
          onClick={() => setShowModal(!showModal)}
          disabled={loading}
        >
          {loading ? "Enviando" : "Enviar propuesta"}
        </button>
      ) : (
        <button
          class="btn btn-primary ml-3"
          onClick={() =>
            ShowAlert.confirm(
              <div>
                <p>
                  Recarga <strong>{usersToCredits} créditos</strong> para enviar
                  tu propuesta a <strong>{totalResults} candidatos</strong>
                </p>
                <p>O refina tu búsqueda.</p>
              </div>
            ).then((response) => {
              if (response.isConfirmed) {
                goToCreditsPage();
              }
            })
          }
        >
          Enviar propuesta
        </button>
      )}

      <Modal show={showModal} onHide={() => hideModal()} size="md">
        <form onSubmit={createMessage}>
          <input type="hidden" name="email_category_id" value="2" />
          <Modal.Body>
            <h2 className="big-title-2">Nueva propuesta de empleo</h2>
            <div className="row bg-light my-3 py-3">
              <div className="col-12">
                <p>
                  Envía tu propuesta de empleo directamente a la casilla de
                  correo de éstos{" "}
                  <strong class="font-weight-bold">
                    {totalResults} talentos
                  </strong>
                  .
                </p>
                <ul>
                  <li>
                    Se descontarán{" "}
                    <strong class="font-weight-bold">
                      {usersToCredits} créditos
                    </strong>{" "}
                    de tu balance
                  </li>
                  <li>
                    La aprobación del anuncio puede tomar hasta{" "}
                    <strong class="font-weight-bold">24hs</strong>.
                  </li>
                  <li>No aceptamos promociones ni publicidad.</li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <label>Asunto del email</label>
              <input type="text" name="subject" class="form-control" />
            </div>
            <div class="form-group">
              <label>Contenido</label>
              <textarea name="body" class="form-control" rows="9"></textarea>
              <p>
                <small class="form-text text-muted">
                  Tip 1: Puedes utilizar [nombre] donde quieras que incluyamos
                  el nombre del candidato.
                </small>
              </p>
              <p>
                <small class="form-text text-muted">
                  Tip 2: Las urls serán convertidas a enlaces
                </small>
              </p>
            </div>
            <div class="form-group">
              <label>Email de contacto</label>
              <input type="text" name="reply_to" class="form-control" />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button type="button" onClick={hideModal} class="btn btn-outline">
              Cancelar
            </button>
            <button type="submit" className="btn btn-primary">
              Enviar a revisión
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
export default SendProposalModal;
