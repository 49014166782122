import React from "react";
import PaymentByStripe from "../../../media/images/payments/stripe-logo.png";

const StripeBanner = () => {
  return (
    <div>
      Pago seguro vía{" "}
      <a href="https://stripe.com/en-gb-es" target="_blank">
        <img src={PaymentByStripe} width="100px" />
      </a>
    </div>
  );
};
export default StripeBanner;
