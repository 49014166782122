import Config from "../Config";
import Auth from "./Auth";

const EmailMessages = {
  list,
  create,
};
export default EmailMessages;

function list() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth.token(),
    },
  };

  return fetch(Config.API_URL + "/email/index", requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function create(searchFilters, subject, body, email_category_id, reply_to) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth.token(),
    },
    body: JSON.stringify({
      ...searchFilters,
      subject,
      body,
      email_category_id,
      reply_to,
    }),
  };

  return fetch(Config.API_URL + "/email/create", requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}
