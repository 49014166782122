import React from "react";

const Terms = () => {
  return (
    <div class="row">
      <div class="col-12">
        <h1 class="big-title-2 my-5 text-center">Términos y condiciones</h1>
      </div>
      <div class="col-sm-12 col-md-6 offset-md-3">
        Bienvenido a HolaJuniors. Este sitio web está disponible para su uso
        siempre y cuando acepte estos términos y condiciones. Al acceder o
        utilizar nuestro sitio web, usted acepta cumplir y estar sujeto a estos
        términos y condiciones. Si no está de acuerdo con los términos y
        condiciones, no utilice el sitio web HolaJuniors.
        <ol>
          <li>USO DEL SITIO WEB</li>
        </ol>
        El sitio web HolaJuniors es un sitio web informativo que proporciona
        contenido educativo y recursos para estudiantes y padres. El contenido
        de este sitio web es propiedad de HolaJuniors y está protegido por leyes
        de propiedad intelectual. El sitio web HolaJuniors no se hace
        responsable por el mal uso de la información proporcionada.
        <ol start="2">
          <li>RESPONSABILIDAD</li>
        </ol>
        El sitio web HolaJuniors no se hace responsable por daños, lesiones o
        pérdidas ocasionadas por el uso del sitio web. El sitio web HolaJuniors
        no garantiza la disponibilidad del sitio web en todo momento y se
        reserva el derecho de modificar, suspender o interrumpir el sitio web en
        cualquier momento sin previo aviso.
        <ol start="3">
          <li>PRIVACIDAD</li>
        </ol>
        El sitio web HolaJuniors se compromete a proteger la privacidad de sus
        usuarios. La información recopilada en el sitio web se utiliza
        únicamente con fines educativos y nunca se compartirá con terceros sin
        el consentimiento expreso del usuario.
        <ol start="4">
          <li>ENLACES A OTROS SITIOS WEB</li>
        </ol>
        El sitio web HolaJuniors puede proporcionar enlaces a otros sitios web
        para la conveniencia de sus usuarios. El sitio web HolaJuniors no es
        responsable del contenido o las políticas de privacidad de estos sitios
        web.
        <ol start="5">
          <li>MODIFICACIONES A LOS TÉRMINOS Y CONDICIONES</li>
        </ol>
        El sitio web HolaJuniors se reserva el derecho de modificar estos
        términos y condiciones en cualquier momento sin previo aviso. Los
        cambios serán efectivos inmediatamente después de su publicación en el
        sitio web.
        <ol start="6">
          <li>LEGISLACIÓN APLICABLE</li>
        </ol>
        Estos términos y condiciones están regidos y se interpretarán de acuerdo
        con las leyes de Argentina sin dar efecto a ningún principio de
        conflicto de leyes. Al utilizar el sitio web HolaJuniors, usted acepta
        estos términos y condiciones en su totalidad. Si tiene alguna pregunta o
        inquietud acerca de los términos y condiciones, por favor contáctenos a
        través de la información proporcionada en nuestro sitio web.
      </div>
    </div>
  );
};
export default Terms;
