import Config from "../Config";
import Auth from "./Auth";

const Resume = {
  getAll,
  uploadPdf,
  getById,
};
export default Resume;

function getAll(username) {
  //   const requestOptions = {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   return fetch(Config.API_URL + "/jobs/metadata", requestOptions)
  //     .then(function (response) {
  //       return response.json();
  //     })
  //     .then(function (json) {
  //       return json;
  //     });
}

function getById(username) {
  //   const requestOptions = {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };
  //   return fetch(Config.API_URL + "/jobs/metadata", requestOptions)
  //     .then(function (response) {
  //       return response.json();
  //     })
  //     .then(function (json) {
  //       return json;
  //     });
}

async function uploadPdf(pdfFile) {
  try {
    const response = await fetch(`${Config.API_URL}/resumes/upload_pdf`, {
      method: "POST",
      body: pdfFile,
      headers: {
        Authorization: "Bearer " + Auth.token(),
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error al subir el pdf:", error);
    throw error; // Puedes manejar el error de otra manera si es necesario
  }
}

function deleteJob(id) {
  //   const requestOptions = {
  //     method: "DELETE",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + Auth.token(),
  //     },
  //   };
  //   return fetch(Config.API_URL + "/jobs/" + id, requestOptions)
  //     .then(function (response) {
  //       return response.json();
  //     })
  //     .then(function (json) {
  //       return json;
  //     });
}
