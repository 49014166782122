import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton from "react-loading-skeleton";
import Text from "../commons/Text";
import { People, OpenInNew } from "@material-ui/icons";
import Money from "../commons/Money";

const JobCard = ({ job, canDelete, deleteJob }) => {
  const history = useHistory();
  const [showDetails, setShowDetails] = useState(false);
  return (
    <div class="card mb-3">
      <div
        class="card-body cursor-pointer"
        onClick={() => setShowDetails(!showDetails)}
      >
        <div className="row">
          <div className="col-12 col-md-9 mb-2">
            <h6 className={`card-text ${!showDetails && "text-truncate"} mb-0`}>
              <strong>{job.title}</strong>
            </h6>
          </div>
          <div className="col-3 d-none d-md-block text-right">
            <span className="badge badge-dark">{job.salary && job.salary}</span>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <span>{job.company_name}</span>
            <span className="text-muted">
              {job?.country?.name && ` • ${job.country.name}`}
            </span>
            <span className="text-muted"> • {job?.seniority?.name}</span>
            <span className="text-muted"> • {job?.type_of_job?.name}</span>
          </div>
        </div>

        <div className="row mb-3 d-md-none">
          <div className="col">
            <span className="badge badge-dark">{job.salary && job.salary}</span>
          </div>
        </div>

        <div className={`row ${showDetails && "mb-3"}`}>
          <div className="col">
            <p
              class={`card-text ${!showDetails && "text-truncate"} skill-tags`}
            >
              {job.skills.length > 0 &&
                job.skills.map((skill) => {
                  return Text.textToBadge(skill.name);
                })}
            </p>
          </div>
        </div>

        {showDetails && (
          <div className="row mb-3">
            <div className="col">{Text.nl2br(job.description)}</div>
          </div>
        )}
      </div>

      {showDetails && (
        <div className="card-body row d-flex justify-content-center align-items-center">
          <div className="col align-middle">
            {canDelete ? (
              <button
                className="btn btn-link text-size-sm"
                onClick={() => deleteJob(job.id)}
              >
                <small>eliminar</small>
              </button>
            ) : (
              <small>
                <span className="d-none d-md-inline">Compartido por </span>
                <a href={`/${job.user.username}`} className="text-mutted">
                  @{job.user.username}
                </a>
              </small>
            )}
          </div>
          <div className="col text-right">
            <a
              href={job.url_to_apply}
              target="_blank"
              className="btn btn-primary btn-sm"
            >
              Solicitar <OpenInNew fontSize="small" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
export default JobCard;

export const ChallengeCardLoading = () => {
  return (
    <div class="card mb-3">
      <div class="card-body">
        <h5 class="card-title">
          <Skeleton />
        </h5>
        <p class="card-text">
          <Skeleton count={2} />
        </p>
        <p class="card-text">
          <Skeleton />
        </p>
      </div>
    </div>
  );
};
