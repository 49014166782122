import Config from "../Config";
import Auth from "./Auth";

const Payment = {
  plans,
  checkout,
  userPayments,
};
export default Payment;

function plans() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  var url = Config.API_URL + "/payments/plans";

  return fetch(url, requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function checkout(planId) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth.token(),
    },
    body: JSON.stringify({ plan_id: planId }),
  };

  return fetch(Config.API_URL + "/payments/checkout", requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

function userPayments() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth.token(),
    },
  };

  return fetch(Config.API_URL + "/payments/userpayments", requestOptions)
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}
