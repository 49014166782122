import React, { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "../context/AppContext";
import { Link } from "react-router-dom";
import ShareProfile from "../user/ShareProfile";

import {
  AssignmentInd,
  PeopleAlt as People,
  Stars,
  Code,
} from "@material-ui/icons";

const Dashboard = () => {
  const [context, setContext] = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  return (
    <div class="row mt-5">
      <div class="col-12">
        <div class="row mb-5 stats-box">
          <div class="col-md-9">
            <h1 class="big-title-2 fade-in">{context.user.name},</h1>
            <p>
              <div class="fade-in">chequea estos accesos rápidos:</div>
            </p>
          </div>
        </div>

        <div class="row big-options">
          <div class="col-md-4 mb-3">
            <div class="box">
              <p class="icon">
                <Code />
              </p>
              <p>
                <strong>Desafíos</strong>
              </p>
              <p>Participa en desafíos de programación</p>
              <p>
                <Link to="/challenges/search" class="btn btn-outline">
                  Ver desafíos
                </Link>
              </p>
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <div class="box">
              <p class="icon">
                <AssignmentInd />
              </p>
              <p>
                <strong>Tu perfil</strong>
              </p>
              <p>Completa tu perfil si quieres aparecer en las búsquedas</p>
              <p>
                <Link to="/user/account" class="btn btn-outline">
                  Modificar mi cuenta
                </Link>
              </p>
            </div>
          </div>

          <div class="col-md-4 mb-3">
            <div class="box">
              <p class="icon">
                <People />
              </p>
              <p>
                <strong>Comparte</strong>
              </p>
              <p>Comparte tu link con todos tus amigos, contactos y LinkedIn</p>
              <p>
                <ShareProfile user={context.user} />
              </p>
            </div>
          </div>

          {context?.user?.available_credits > 0 && (
            <div className="col-md-4 mb-3">
              <div class="box">
                <p class="icon">
                  <Stars />
                </p>
                <p>
                  <strong>
                    Tienes {context.user.available_credits} créditos
                  </strong>
                </p>
                <p>Carga créditos en tu cuenta para contactar candidatos</p>
                <p>
                  <Link to="/user/credits" class="btn btn-outline">
                    Cargar créditos
                  </Link>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
