import React, { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";

const PaymentCallback = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const status = searchParams.get("status");
  const [message, setMessage] = useState(null);

  useEffect(() => {
    switch (status) {
      case "success":
        setMessage({
          title: "Pago realizado",
          description: "Hemos recibido el pago con éxito",
          buttonText: "Ver mis créditos",
        });
        break;
      case "cancel":
        setMessage({
          title: "Pago CANCELADO",
          description: "No se ha podido procesar el pago de tus créditos",
          buttonText: "Vuelve a intentar",
        });
        break;

      default:
        break;
    }
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100">
      <div className="mb-3 text-center">
        <h1 className="big-title-2">{message && message.title}</h1>
        <p>{message && message.description}</p>
      </div>
      <div>
        <Link to="/user/credits" className="btn btn-primary d-inline">
          {message && message.buttonText}
        </Link>
      </div>
    </div>
  );
};

export default PaymentCallback;
