import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import UserApi from "../../api/User";
import CreditsPageTitle from "../credits/CreditsPageTitle";
import Text from "../commons/Text";
import FreelancerModal from "./FreelancerModal";
import EmptyState from "../commons/EmptyState";

function UserContacts() {
  const [loading, setLoading] = useState(false);
  const [userContacts, setUserContacts] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const getContacts = async () => {
    setLoading(true);
    const contacts = await UserApi.contacts();
    setUserContacts(contacts.data);
    setLoading(false);
  };

  const handleClose = () => {
    setSelectedUser(null);
  };

  useEffect(() => {
    getContacts();
  }, []);

  return (
    <>
      <CreditsPageTitle title="Contactos" />

      {userContacts && userContacts.length > 0 && (
        <div class="table-responsive">
          <table className="table table-striped table-contacts">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Email</th>
                <th>Teléfono</th>
                <th>Fecha del contacto</th>
              </tr>
            </thead>
            <tbody>
              {userContacts.map((contact) => {
                return (
                  <tr onClick={() => setSelectedUser(contact.user)}>
                    <td>{contact.user.name}</td>
                    <td>{contact.user.email}</td>
                    <td>{contact.user.phone_number}</td>
                    <td>
                      <Moment format="DD-MM-YYYY">{contact.created_at}</Moment>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <FreelancerModal
        user={selectedUser}
        showModal={selectedUser ? true : false}
        handleClose={handleClose}
        isContact
      />

      <EmptyState
        title="No tienes contactos"
        description="Crea nuevos contactos para verlos aquí"
        actions={
          <Link to="/search/people" className="btn btn-primary">
            Nueva búsqueda
          </Link>
        }
        show={!loading && !userContacts?.length}
      />
    </>
  );
}

export default UserContacts;
