import React, { useContext, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import ReactGA from "react-ga4";
import { AppContext } from "../components/context/AppContext";
import AuthLayout from "../components/layouts/AuthLayout";
import AdminLayout from "../components/layouts/AdminLayout";
import WebsiteLayout from "../components/layouts/WebsiteLayout";
import AppLoading from "../components/layouts/AppLoading";
import Auth from "../api/Auth";

ReactGA.initialize("G-0295HJ31D9");

const sendAnalyticsData = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
};

export const AuthRoute = ({ component: Component, title, ...rest }) => {
  const [context, setContext] = useContext(AppContext);

  window.document.title = title;
  sendAnalyticsData();

  if (context.loading) {
    return <AppLoading />;
  } else {
    if (context.user) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    } else {
      return (
        <Route
          {...rest}
          render={(matchProps) => (
            <AuthLayout>
              <Component {...matchProps} />
            </AuthLayout>
          )}
        />
      );
    }
  }
};

export function PrivateRoute({ children, title, ...rest }) {
  const [context, setContext] = useContext(AppContext);

  window.document.title = title;
  sendAnalyticsData();

  if (context.loading) {
    return <AppLoading />;
  } else {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          context.user ? (
            <AdminLayout>{children}</AdminLayout>
          ) : (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }
}

export function WebsiteRoute({ children, title, ...rest }) {
  const [context, setContext] = useContext(AppContext);

  window.document.title = title;
  sendAnalyticsData();

  if (context.loading) {
    return <AppLoading />;
  } else {
    return (
      <Route
        {...rest}
        render={({ location }) => <WebsiteLayout>{children}</WebsiteLayout>}
      />
    );
  }
}
