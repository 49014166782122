import React from "react";
import ShowAlert from "../commons/ShowAlert";
import Text from "../commons/Text";

window.copy = () => {
  Text.copy("url-to-share-profile");
  ShowAlert.success(
    "Link copiado, ya puedes pegarlo donde quieras compartirlo"
  );
};

const ShareProfile = (props) => {
  const link = "https://holajuniors.com/" + props.user.username;

  const message = `<div>
      <p>Copia este link y compártelo en tu LinkedIn, GitHub o el medio que más utilices</p>
      <div class="mb-2">
        <input type="text" class="form-control" name="username" value=${link} id="url-to-share-profile">
      </div>
      <button onClick="copy()" class="btn btn-link"><u>Copiar enlace</u></button>
    </div>`;

  const handleShare = (e) => {
    e.preventDefault();
    ShowAlert.blank(message);
  };

  return (
    <button
      class={props.buttonClass ? props.buttonClass : "btn btn-outline"}
      onClick={handleShare}
    >
      {props.buttonLabel ? props.buttonLabel : "Compartir"}
    </button>
  );
};
export default ShareProfile;
