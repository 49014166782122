import React, { useState, useEffect } from 'react'
import Auth from '../../api/Auth'

const initialContext = {
  user: null,
  token: Auth.token(),
  loading: true,
}

const AppContext = React.createContext([
  initialContext,
  () => {}
])

const AppProvider = (props) => {
  const [state, setState] = useState(initialContext);

  useEffect(() => {
    if ( Auth.token() ) {
      Auth.me().then(data => {
        setState({
          ...state,
          user: data.user,
          loading: false,
        })
      })
    } else {
      setState({
        ...state,
        loading: false,
      })
    }
  }, [])

  return (
    <AppContext.Provider value={[state, setState]}>
      {props.children}
    </AppContext.Provider>
  );
}

export { AppContext, AppProvider };
