import React, { useState, useEffect } from "react";
import Content from "../../api/Content";
import { Button, Form, Card, Row, Col } from "react-bootstrap";
import { ThumbUp, Delete } from "@material-ui/icons";

const CommentComments = ({ contentId }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Content.getComments(contentId).then((data) => {
      if (data.status === "ok") {
        setComments(data.data);
      }
    });
  }, [contentId]);

  const handleCommentSubmit = () => {
    if (!newComment.trim()) return;

    setLoading(true);

    Content.comment(contentId, newComment)
      .then((data) => {
        if (data.status === "ok") {
          setComments((prev) => [data.data, ...prev]);
          setNewComment("");
        }
      })
      .finally(() => setLoading(false));
  };

  const handleCommentLike = (commentId, isLiked) => {
    const method = isLiked ? "DELETE" : "POST";

    Content.commentLike(commentId, isLiked).then((data) => {
      if (data.status === "ok") {
        setComments((prev) =>
          prev.map((comment) =>
            comment.id === commentId
              ? {
                  ...comment,
                  likes_count: isLiked
                    ? comment.likes_count - 1
                    : comment.likes_count + 1,
                  liked: !isLiked,
                }
              : comment
          )
        );
      }
    });
  };

  const handleDeleteComment = (commentId) => {
    if (window.confirm("¿Seguro deseas eliminar este comentario?")) {
      Content.deleteComment(commentId).then((data) => {
        if (data.status === "ok") {
          setComments((prevComments) =>
            prevComments.filter((comment) => comment.id !== commentId)
          );
        }
      });
    }
  };

  return (
    <div>
      {/* Campo para añadir comentarios */}
      <Form.Group controlId="newComment">
        <Form.Control
          as="textarea"
          rows={3}
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Escribe tu comentario aquí..."
        />
        <Button
          className="mt-2"
          variant="primary"
          onClick={handleCommentSubmit}
          disabled={loading}
        >
          {loading ? "Enviando..." : "Enviar"}
        </Button>
      </Form.Group>

      {/* Listado de comentarios */}
      <div className="mt-4">
        {comments.map((comment) => (
          <Card key={comment.id} className="mb-3">
            <Card.Body className="pb-0">
              <Row>
                <Col xs={2}>
                  <img
                    src={comment?.user?.avatar}
                    alt={comment?.user?.name}
                    className="rounded-circle"
                    style={{ width: "40px", height: "40px" }}
                  />
                </Col>
                <Col xs={10}>
                  <h6 className="m-0">{comment?.user?.name}</h6>
                  <small className="text-muted">
                    {new Date(comment.created_at).toLocaleDateString()}
                  </small>
                  <p>{comment.comment}</p>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="d-flex justify-content-between align-items-center p-0">
              <Button
                variant="link"
                className={`p-0 ${
                  comment.liked ? "text-primary" : "text-dark"
                }`}
                onClick={() => handleCommentLike(comment.id, comment.liked)}
              >
                <ThumbUp style={{ fontSize: "20px" }} />{" "}
                <span>{comment.likes_count > 0 && comment.likes_count}</span>
              </Button>
              {comment.can_delete && (
                <Button
                  variant="link"
                  className="text-danger p-0 ml-2"
                  onClick={() => handleDeleteComment(comment.id)}
                >
                  <Delete style={{ fontSize: "20px" }} />
                </Button>
              )}
            </Card.Footer>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default CommentComments;
