import Config from "../Config";
import Auth from "./Auth";

const Content = {
  search,
  get,
  create,
  deleteContent,
  like,
  favorite,
  urlPreview,
  getComments,
  comment,
  commentLike,
  deleteComment,
};
export default Content;

async function get(slugOrId) {
  return fetch(`${Config.API_URL}/content/${slugOrId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${Auth.token()}`,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error("No se pudo obtener el contenido");
    }
    return response.json();
  });
}

async function deleteComment(commentId) {
  return fetch(`${Config.API_URL}/comment/${commentId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${Auth.token()}`,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error("No se pudo eliminar el comentario");
    }
    return response.json();
  });
}

async function commentLike(commentId, isLiked) {
  const method = isLiked ? "DELETE" : "POST";
  return fetch(`${Config.API_URL}/comment/${commentId}/like`, {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  }).then((response) => response.json());
}

async function comment(contentId, newComment) {
  return fetch(`${Config.API_URL}/content/${contentId}/comment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({ comment: newComment }),
  }).then((response) => response.json());
}

async function getComments(contentId) {
  return fetch(`${Config.API_URL}/content/${contentId}/comments`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  }).then((response) => response.json());
}

async function urlPreview(url) {
  return fetch(`${Config.API_URL}/content/url`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: JSON.stringify({ url }),
  }).then((response) => {
    return response.json();
  });
}

async function favorite(contentId, isFavorited = false) {
  const method = isFavorited ? "DELETE" : "POST";

  return fetch(`${Config.API_URL}/content/${contentId}/favorite`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(
        isFavorited
          ? "No se pudo quitar de favoritos"
          : "No se pudo añadir a favoritos"
      );
    }
    return response.json();
  });
}

async function like(contentId, isLiked = false) {
  const method = isLiked ? "DELETE" : "POST";

  // Realizamos la petición a la API
  return fetch(`${Config.API_URL}/content/${contentId}/like`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`, // Si usas autenticación
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(
        isLiked ? "No se pudo eliminar el like" : "No se pudo añadir el like"
      );
    }
    return response.json();
  });
}

function search(page, setContents, setPagination, setLoading, setError) {
  fetch(`${Config.API_URL}/contents?page=${page}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Error al cargar los contenidos");
      }
      return response.json();
    })
    .then((data) => {
      if (data.status === "ok" && data.data) {
        setContents((prevContents) => [...prevContents, ...data.data.data]);
        setPagination({
          current_page: data.data.current_page,
          last_page: data.data.last_page,
        });
      } else {
        throw new Error("La respuesta de la API no tiene el formato esperado");
      }
      setLoading(false);
    })
    .catch((err) => {
      setError(err.message);
      setLoading(false);
    });
}

async function create(form) {
  return fetch(`${Config.API_URL}/content/create`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${Auth.token()}`,
    },
    body: form,
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`Error ${response.status}: ${response.statusText}`);
    }
    return response.json();
  });
}

async function deleteContent(contentId) {
  const response = await fetch(Config.API_URL + `/content/${contentId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.token()}`, // Si usas autenticación
    },
  });

  if (!response.ok) {
    throw new Error("Error al intentar eliminar el contenido.");
  }

  return await response.json();
}
