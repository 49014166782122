import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Launch, MoreHoriz } from "@material-ui/icons";
import { Dropdown } from "react-bootstrap";
import { AppContext } from "../context/AppContext";

const AccountPageTitle = (props) => {
  const [context, setContext] = useContext(AppContext);
  const { pathname } = useLocation();

  return (
    <div class="row page-title">
      <div class="col-6">
        <h1 class="big-title-2">{props.title}</h1>
      </div>
      <div class="col-6 text-right tools-container">
        <Dropdown>
          <Dropdown.Toggle variant="outline">
            <MoreHoriz />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              as={Link}
              to="/user/account"
              active={pathname == "/user/account" ? true : false}
            >
              Mi cuenta
            </Dropdown.Item>

            <Dropdown.Item
              as={Link}
              to="/user/changepassword"
              active={pathname == "/user/changepassword" ? true : false}
            >
              Contraseña
            </Dropdown.Item>

            <Dropdown.Item as={Link} to="/auth/logout">
              Cerrar sesión
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
export default AccountPageTitle;
