import React from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Logo from "../../media/images/holajuniors-logo.png";

const AuthLayout = ({ children, ...rest }) => {
  return (
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 col-md-5 auth-layout-left">
          <div class="row mt-3">
            <div class="col-sm-12">
              <Link to="/">
                <img src={Logo} class="logo-auth" />
              </Link>
            </div>
          </div>

          {children}
        </div>
        <div class="col-sm-7 d-none d-md-block auth-layout-right">
          <div class="credits">
            <a href="https://unsplash.com/@farreal" target="_blank">
              @farreal
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
